
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import ImageAttachment from "@/components/fields/ImageAttachment.vue";
import { Video, VideoViewModel } from "@/models/videos";
import dayjs from "dayjs";
import { Event } from "@/models/event";
import { VideosForm } from "@/helpers/classes/Form";
import { VehicleCategories } from "@/models/vehicleCategories";
import { videosHook } from "@/helpers/videos";

@Component({
  components: {
    ImageAttachment,
  },
  filters: {
    displayDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    },
  },
})
export default class VideosModal extends Vue {
  @PropSync("open") modal!: boolean;
  @Prop() readonly video!: Video | undefined;
  form: VideosForm | null = null;
  valid = false;
  loading = false;
  videoCategories: VehicleCategories[] = [];
  rules = {
    mileage: (v: string) => v == null || Number(v) >= 0 || "Cannot be empty",
    required: (v: string) => !!v || "Cannot be empty",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
    minFileSize: (value: { size: number }): boolean | string =>
      !value || value.size > 146484.375 || "Min File Dimensions are 500x300",
    vin: (value: { length: number }): boolean | string =>
      !value ||
      value.length == 17 ||
      "Standard VIN numbers are 17 characters long",
    requiredMultiple: (v: string[]): boolean | string =>
      !v || v.length > 0 || "At least one option required.",
  };

  @Watch("open")
  async onOpenChanged(val: boolean) {
    if (val == true) {
      await this.init();
      this.form = new VideosForm(this.video);
    } else {
      this.close();
    }
  }

  @Watch("form.form", {
    deep: true,
  })
  onFormChanges() {
    //console.log("Has changes");
    if (this.form && this.form.video) {
      this.form.compare();
    }
  }

  close(): void {
    this.$emit("close");
    this.modal = false;
  }

  get getUnchanged() {
    return this.form ? this.form.unchanged : true;
  }

  async init(): Promise<void> {
    try {
      this.loading = true;
      const res = await videosHook.GetVideoCategories();
      //console.log("Video Categories:", res);
      this.videoCategories = res;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async submit(): Promise<void> {
    try {
      this.loading = true;
      if (this.form) {
        await this.form.submit();
      }
      this.close();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
      this.$emit("refresh");
    }
  }
}
