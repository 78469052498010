import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import dayjs from "dayjs";
import { Video, VideoCommentsViewModel, VideoViewModel } from "@/models/videos";
import { VehicleCategories } from "@/models/vehicleCategories";
import { Query } from "@/models/search";

const videos = () => {
  const baseUrl = "/api/Video";
  const GetAllVideos = async (
    query: Query
  ): Promise<{ videos: Video[]; resultCount: number }> => {
    const res = await axiosInstance.post(baseUrl + "/GetAllVideos", query);
    return Promise.resolve(res.data);
  };

  const UpdateVideo = async (data: VideoViewModel): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/UpdateVideo", data);
    return Promise.resolve(res.data);
  };

  const UpdateVideoStatus = async (data: VideoViewModel): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/UpdateVideoStatus", data);
    return Promise.resolve(res.data);
  };

  const DeleteVideo = async (data: VideoViewModel): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/DeleteVideo", data);
    return Promise.resolve(res.data);
  };

  const CreateVideo = async (data: VideoViewModel): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/CreateVideo", data);
    return Promise.resolve(res.data);
  };

  const GetVideoCategories = async (): Promise<VehicleCategories[]> => {
    const res = await axiosInstance.get(baseUrl + `/GetVideoCategoriess`);

    return Promise.resolve(res.data);
  };

  const AddVideoCategories = async (data: VehicleCategories): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + `/AddVideoCategories`, data);

    return Promise.resolve(res.data);
  };

  const UpdateVideoCategories = async (
    data: VehicleCategories
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/UpdateVideoCategories`,
      data
    );

    return Promise.resolve(res.data);
  };

  const DeleteVideoCategories = async (
    data: VehicleCategories
  ): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/DeleteVideoCategories`,
      data
    );

    return Promise.resolve(res.data);
  };

  const AddCommentToVideo = async (
    data: VideoCommentsViewModel
  ): Promise<any> => {
    const res = await axiosInstance.post(baseUrl + "/AddCommentToVideo", data);
    return Promise.resolve(res.data);
  };

  const DeleteCommentOnVideo = async (
    data: VideoCommentsViewModel
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/DeleteCommentOnVideo",
      data
    );
    return Promise.resolve(res.data);
  };

  const UpdateCommentOnVideo = async (
    data: VideoCommentsViewModel
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdateCommentOnVideo",
      data
    );
    return Promise.resolve(res.data);
  };

  return {
    GetAllVideos,
    UpdateVideo,
    UpdateVideoStatus,
    DeleteVideo,
    CreateVideo,
    GetVideoCategories,
    AddVideoCategories,
    UpdateVideoCategories,
    DeleteVideoCategories,
    AddCommentToVideo,
    DeleteCommentOnVideo,
    UpdateCommentOnVideo,
  };
};

const videosHook = videos();
export { videosHook };
