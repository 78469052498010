
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import ImageAttachment from "@/components/fields/ImageAttachment.vue";
import Loader from "@/store/modules/Loader";
import { ProductCategoriesForm } from "@/helpers/classes/Form";
import { NotificationLib } from "@/helpers";
import { storeHook } from "@/helpers/store";
import { ProductCategories, ProductsViewModel } from "@/models/store";

@Component({
  components: {
    ImageAttachment,
  },
})
export default class ProductCategoriesModal extends Vue {
  @PropSync("open") modal!: boolean;
  form: ProductCategoriesForm | null = null;
  manageModal = false;
  modalItem: ProductCategories | undefined | null = null;
  valid = false;
  loading = false;
  items: ProductCategories[] = [];
  search = "";
  sortDesc = false;
  headers = [
    { text: "Category", value: "category" },
    { text: "", value: "actions", sortable: false },
  ];
  rules = {
    mileage: (v: string) => v == null || Number(v) >= 0 || "Cannot be empty",
    required: (v: string) => !!v || "Cannot be empty",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
    minFileSize: (value: { size: number }): boolean | string =>
      !value || value.size > 146484.375 || "Min File Dimensions are 500x300",
    vin: (value: { length: number }): boolean | string =>
      !value ||
      value.length == 17 ||
      "Standard VIN numbers are 17 characters long",
    requiredMultiple: (v: string[]): boolean | string =>
      !v || v.length > 0 || "At least one option required.",
  };

  openEdit(i: ProductCategories): void {
    this.modalItem = i;
    this.manageModal = true;
  }

  openAdd(): void {
    this.modalItem = undefined;
    this.manageModal = true;
  }

  @Watch("form.form", {
    deep: true,
  })
  onFormChanges() {
    //console.log("Has changes");
    if (this.form) {
      this.form.compare();
    }
    // this.unchanged = false;
  }

  @Watch("manageModal")
  async onManageOpenChanged(val: boolean) {
    if (val == true) {
      this.form = new ProductCategoriesForm(this.modalItem!);
    } else {
      this.closeManageModal();
    }
  }

  @Watch("open")
  async onOpenChanged(val: boolean) {
    if (val == true) {
      this.init();
    } else {
      this.close();
    }
  }

  close(): void {
    this.modal = false;
  }

  closeManageModal(): void {
    this.manageModal = false;
  }

  get getUnchanged() {
    return this.form ? this.form.unchanged : true;
  }
  async init(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      const res = await storeHook.GetProductCategoriess();
      // console.log("Product Categories:", res);
      this.items = res;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }
  async deleteCategory(i: ProductCategories): Promise<void> {
    try {
      Loader.setLoadingState(true);
      const res = await storeHook.DeleteProductCategories(i);

      NotificationLib.createSuccessNotification("Category Deleted");
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
      await this.init();
    }
  }
  async submit(): Promise<void> {
    try {
      this.loading = true;
      if (this.form) {
        await this.form.submit();
      }
      this.closeManageModal();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
      await this.init();
    }
  }
}
