
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import ImageAttachment from "@/components/fields/ImageAttachment.vue";
import { Vehicle } from "@/models/vehicle";
import dayjs from "dayjs";
import { VehicleAttachment } from "@/models/files";
import { vehicleLib } from "@/helpers/vehicle";
import { DataTableHeader } from "vuetify";

@Component({
  components: {
    ImageAttachment,
  },
  filters: {
    displayDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    },
  },
})
export default class VehicleModal extends Vue {
  @PropSync("open") modal!: boolean;
  @Prop() readonly vehicle!: Vehicle | undefined;
  tab = "vehicle";
  attachments: VehicleAttachment[] = [];
  valid = false;
  loading = false;
  headers: DataTableHeader[] = [
    {
      text: "Description",
      value: "description",
    },
    {
      text: "Service Date",
      value: "serviceDate",
    },
    {
      text: "KM",
      value: "km",
    },
    /*  {
      text: "Actions",
      value: "actions",
      width: "100",
    }, */
  ];
  rules = {
    mileage: (v: string) => v == null || Number(v) >= 0 || "Cannot be empty",
    required: (v: string) => !!v || "Cannot be empty",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
    minFileSize: (value: { size: number }): boolean | string =>
      !value || value.size > 146484.375 || "Min File Dimensions are 500x300",
    vin: (value: { length: number }): boolean | string =>
      !value ||
      value.length == 17 ||
      "Standard VIN numbers are 17 characters long",
    requiredMultiple: (v: string[]): boolean | string =>
      !v || v.length > 0 || "At least one option required.",
  };

  @Watch("open")
  async onOpenChanged(val: boolean) {
    if (val == true) {
      if (this.vehicle) {
        this.attachments = await vehicleLib.GetAllAttachments(this.vehicle.id);
      }
    } else {
      this.close();
    }
  }

  close(): void {
    this.$emit("close");
    this.tab = "vehicle";
    this.modal = false;
  }

  get expanded() {
    return this.attachments.filter((item) => item.expand);
  }
  set expanded(expandedItems: any) {
    this.attachments.forEach((item) => {
      item.expand = expandedItems.includes(item);
    });
  }
}
