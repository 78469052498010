
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/store/modules/Loader";
import { vehicleLib } from "@/helpers/vehicle";
import { Vehicle } from "@/models/vehicle";
import VehiclesModal from "@/views/modals/Vehicles.vue";

@Component({ components: { VehiclesModal } })
export default class Vehicles extends Vue {
  items: Vehicle[] = [];
  search = "";
  sortDesc = false;
  sortBy = "make";
  modal = false;
  modalItem: Vehicle | undefined | null = null;
  headers = [
    { text: "Make", value: "make" },
    { text: "Model", value: "model" },
    { text: "Year", value: "year" },
    { text: "Mileage (km)", value: "mileage" },
    { text: "Registration", value: "vehicleRegistration" },
    { text: "Owner", value: "createdBy" },
    { text: "", value: "actions", sortable: false },
  ];
  keys = [
    { text: "Make", value: "make" },
    { text: "Model", value: "model" },
    { text: "Year", value: "year" },
    { text: "Mileage", value: "mileage" },
    { text: "Registration", value: "vehicleRegistration" },
    { text: "Owner", value: "createdBy" },
  ];

  openModal(i: Vehicle): void {
    this.modalItem = i;
    this.modal = true;
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      const res = await vehicleLib.GetAllVehicles();
      //console.log("Vehicles :", res);
      this.items = [];
      if (res) {
        res.map((elem) => {
          if (elem.deleted == false) this.items.push(elem);
        });
      }
      //this.items = res;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }
}
