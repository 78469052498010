
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/store/modules/Loader";
import { userLib } from "@/helpers/user";
import { GeneralUserModel, UserFormModel } from "@/models/user";
import { Company } from "@/models/company";
import UserModal from "@/views/modals/Users.vue";

@Component({
  components: {
    UserModal,
  },
})
export default class Users extends Vue {
  items: { company: Company; user: GeneralUserModel }[] = [];
  search = "";
  sortDesc = false;
  sortBy = "nameOfUser";
  modal = false;
  modalItem: UserFormModel | undefined | null = null;
  modalUserId: string | null = null;
  deleteModal = false;
  deleteItem = {} as any;
  headers = [
    { text: "Name", value: "user.nameOfUser" },
    { text: "Username/ Email", value: "user.userName" },
    { text: "Phone No.", value: "user.phoneNumber" },
    { text: "Province", value: "user.address" },
    { text: "Account Type", value: "user.userType" },
    { text: "", value: "actions", sortable: false },
  ];
  keys = [
    { text: "Name", value: "user.nameOfUser" },
    { text: "Username/ Email", value: "user.userName" },
    { text: "Phone No.", value: "user.phoneNumber" },
    { text: "Province", value: "user.address" },
    { text: "Account Type", value: "user.userType" },
  ];

  openEdit(i: { company: Company; user: GeneralUserModel }): void {
    this.modalUserId = i.user.id;
    this.modalItem = {
      businessAddress: {
        buildingName: i.company ? i.company.businessBuildingName : null,
        city: i.company ? i.company.businessCity : null,
        complexName: i.company ? i.company.businessComplexName : null,
        province: i.company ? i.company.businessProvince : null,
        street: i.company ? i.company.businessStreet : null,
        streetNo: i.company ? i.company.businessStreetNo : null,
        suburb: i.company ? i.company.businessSuburb : null,
        zipCode: i.company ? i.company.businessZipCode : null,
      },
      businessEmail: i.company ? i.company.businessEmail : null,
      businessName: i.company ? i.company.businessName : null,
      businessPhoneNumber: i.company ? i.company.businessPhoneNo : null,
      businessRegNo: i.company ? i.company.businessRegNo : null,
      companyId: i.company ? i.company.id : null,
      companyTypeId: i.company ? i.company.companyType : null,
      emailNotification: i.user.emailNotification,
      name: i.user.nameOfUser,
      phoneNumber: i.user.phoneNumber,
      pushNotification: i.user.pushNotification,
      rMINo: i.company ? i.company.rmiNo : null,
      userEmail: i.user.email,
      userProvince: i.user.address,
      userType: i.user.userType,
      vATNo: i.company ? i.company.vatNo : null,
      whatsAppNotficication: i.user.whatsAppNotficication,
      logo: i.company
        ? i.user.userType == "Individual"
          ? null
          : i.company && i.company.logo
          ? i.company.logo
          : new Image()
        : new Image(),
      password: null,
      isAdmin: i.user.isAdmin,
    };
    this.modal = true;
  }

  openAdd(): void {
    /*  this.modalData.type = "add";
    this.modalData.fields = {}; */
    this.modal = true;
  }

  openDelete(i: any): void {
    this.deleteItem = i;
    this.deleteModal = true;
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      const res = await userLib.getAllUsers();
      // console.log("Users :", res);
      this.items = res;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }
}
