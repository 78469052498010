
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/store/modules/Loader";
import { storeHook } from "@/helpers/store";
import { ProductsViewModel } from "@/models/store";
import Products from "@/views/modals/Products.vue";
import ProductCategories from "@/views/modals/ProductCategories.vue";
import { NotificationLib } from "@/helpers";
import { Query } from "@/models/search";
import { eventsHook } from "@/helpers/events";

interface ProductsTableViewModel extends ProductsViewModel {
  statusFormatted: string;
}

@Component({ components: { Products, ProductCategories } })
export default class Store extends Vue {
  items: ProductsTableViewModel[] = [];
  search = "";
  sortDesc = false;
  sortBy = "";
  modal = false;
  modalItem: ProductsViewModel | undefined | null = null;
  deleteModal = false;
  deleteItem = {} as ProductsViewModel;
  categoriesModal = false;
  query: Query = {
    searchString: "",
    filterOnList: 3,
    page: 0,
    itemsPerPage: -1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
  status = [
    { description: "Pending", color: "grey darken-2" },
    { description: "Approved", color: "success" },
    { description: "Declined", color: "error" },
  ];
  headers = [
    { text: "Product", value: "title" },
    { text: "Price", value: "price" },
    { text: "Category", value: "category" },
    { text: "Description", value: "description" },
    { text: "Status", value: "statusFormatted" },
    { text: "", value: "actions", sortable: false },
  ];
  keys = [
    { text: "Product", value: "title" },
    { text: "Price", value: "price" },
    { text: "Category", value: "category" },
    { text: "Description", value: "description" },
    { text: "Status", value: "statusFormatted" },
  ];

  openEdit(i: any): void {
    console.log(i);
    this.modalItem = i;
    this.modal = true;
  }

  openAdd(): void {
    this.modalItem = undefined;
    this.modal = true;
  }

  openCategories(): void {
    this.categoriesModal = true;
  }

  openDelete(i: any): void {
    this.deleteItem = i;
    this.deleteModal = true;
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async setStatus(
    item: ProductsTableViewModel,
    status: { description: string; color: string }
  ): Promise<void> {
    // console.log("Video:", item, "Status: ", status);
    if (status.description == "Pending") {
      item.status = 0;
    } else if (status.description == "Approved") {
      item.status = 1;
    } else if (status.description == "Declined") {
      item.status = 2;
    }
    try {
      Loader.setLoadingState(true);
      const res = await storeHook.UpdateProductStatus(item);
      await this.init();
      NotificationLib.createSuccessNotification("Status Updated");
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }

  getStatusColor(status: string): string {
    var findRes = this.status.find((elem) => elem.description == status);
    if (findRes) {
      return findRes.color;
    } else {
      return "grey";
    }
  }

  formatStatus(statusId: number | undefined): string {
    if (statusId == 0) {
      return "Pending";
    } else if (statusId == 1) {
      return "Approved";
    } else if (statusId == 2) {
      return "Declined";
    } else {
      return "";
    }
  }

  async init(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      const res = await storeHook.GetProducts(this.query);
      this.items = [];
      res.products.map((elem) => {
        this.items.push({
          category: elem.category,
          description: elem.description,
          images: elem.images,
          price: elem.price,
          statusFormatted: this.formatStatus(elem.status),
          title: elem.title,
          id: elem.id,
          user: elem.user,
          status: elem.status,
          createdBy: elem.createdBy,
        });
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }

  async deleteProduct(): Promise<void> {
    try {
      Loader.setLoadingState(true);

      const res = await storeHook.DeleteProducts(this.deleteItem);
      NotificationLib.createSuccessNotification("Product Deleted");
      await this.init();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      Loader.setLoadingState(false);
    }
  }
}
