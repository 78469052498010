import { axiosInstance } from "@/plugins/axios";
import { VehicleTypes } from "@/models/vehicleTypes";
import { VehicleCategories } from "@/models/vehicleCategories";

const vehicleTypesLib = () => {
  const GetAllVehicleMakes = async (): Promise<string[]> => {
    const res = await axiosInstance.get(`/api/VehicleTypes/GetAllVehicleMakes`);

    return Promise.resolve(res.data);
  };

  const GetVehicleModel = async (make: string): Promise<string[]> => {
    const res = await axiosInstance.post(
      `/api/VehicleTypes/GetVehicleModel`,
      null,
      {
        params: { make: make },
      }
    );

    return Promise.resolve(res.data);
  };

  const GetVehicleYear = async (
    make: string,
    model: string
  ): Promise<number[]> => {
    const res = await axiosInstance.post(
      `/api/VehicleTypes/GetVehicleYear`,
      null,
      {
        params: { make: make, model: model },
      }
    );

    return Promise.resolve(res.data);
  };

  const UpdateNewUserEnteredDataToList = async (
    data: VehicleTypes
  ): Promise<void> => {
    const res = await axiosInstance.post(
      `/api/VehicleTypes/UpdateNewUserEnteredDataToList`,
      data
    );

    return Promise.resolve(res.data);
  };

  const AddVehicleToList = async (data: VehicleTypes): Promise<void> => {
    const res = await axiosInstance.post(
      `/api/VehicleTypes/AddVehicleToList`,
      data
    );

    return Promise.resolve(res.data);
  };

  const GetAllVehicleTypes = async (): Promise<VehicleTypes[]> => {
    const res = await axiosInstance.get(`/api/VehicleTypes/GetAllVehicleTypes`);

    return Promise.resolve(res.data);
  };

  const GetVehicleCategories = async (): Promise<VehicleCategories[]> => {
    const res = await axiosInstance.get(
      `/api/VehicleTypes/GetVehicleCategories`
    );

    return Promise.resolve(res.data);
  };

  const AddVehicleCategories = async (
    data: VehicleCategories
  ): Promise<void> => {
    const res = await axiosInstance.post(
      `/api/VehicleTypes/AddVehicleCategories`,
      data
    );

    return Promise.resolve(res.data);
  };

  const UpdateVehicleCategories = async (
    data: VehicleCategories
  ): Promise<void> => {
    const res = await axiosInstance.post(
      `/api/VehicleTypes/UpdateVehicleCategories`,
      data
    );

    return Promise.resolve(res.data);
  };

  const DeleteVehicleCategories = async (
    data: VehicleCategories
  ): Promise<void> => {
    const res = await axiosInstance.post(
      `/api/VehicleTypes/DeleteVehicleCategories`,
      data
    );

    return Promise.resolve(res.data);
  };

  return {
    GetAllVehicleMakes,
    GetVehicleModel,
    GetVehicleYear,
    GetAllVehicleTypes,
    UpdateNewUserEnteredDataToList,
    AddVehicleToList,
    GetVehicleCategories,
    AddVehicleCategories,
    UpdateVehicleCategories,
    DeleteVehicleCategories,
  };
};

const vehicleTypeLib = vehicleTypesLib();
export { vehicleTypeLib };
