import { Image } from "./Files";
import { NotificationLib, UserLib } from "..";
import { Profile, Registration, UserFormModel } from "@/models/user";
import { VehicleTypes } from "@/models/vehicleTypes";
import { vehicleTypeLib } from "@/helpers/vehicleTypes";
import { Event, EventCategories, Event_CREATE } from "@/models/event";
import { eventsHook } from "@/helpers/events";
import dayjs from "dayjs";
import { videosHook } from "@/helpers/videos";
import { Video, VideoViewModel } from "@/models/videos";
import { PushNotifications } from "@capacitor/push-notifications";
import { VehicleCategories } from "@/models/vehicleCategories";
import { ProductCategories, ProductsViewModel } from "@/models/store";
import { storeHook } from "../store";
import { ImageAttachment } from "@/models/files";
import {
  AdminNotificationModel,
  AdminNotificationModel_CREATE,
} from "@/models/adminNotifications";
import { adminNotificationsHook } from "../adminNotifications";
class Form {}

class UserRegisterForm extends Form {
  public form: Registration;
  /**
   * This is to determine if it is new reg or edit of profile.
   */
  public readonly reference?: Profile;
  public confirmPassword: string | null = null;
  public acceptTCs = false;
  public reveal = false;
  public revealConfirm = false;
  public captcha = false;
  public type: "personal" | "business";

  constructor(type: "personal" | "business", profile?: Profile) {
    super();
    //console.log("Construct form : ", profile != undefined, type);
    this.type = type;
    this.reference = profile;
    this.form = this.initForm(type, profile);
  }
  reset(val: "personal" | "business") {
    this.type = val;
    this.acceptTCs = false;
    this.confirmPassword = null;
    this.captcha = false;
    this.reveal = false;
    this.revealConfirm = false;
    this.form = this.initForm(val, this.reference);
    // ? this.reference
    // : {
    //     name: null,
    //     password: null,
    //     email: null,
    //     logo: val == "personal" ? null : new Image().toValue(),
    //     phoneNumber: null,
    //     userType: val == "personal" ? "Individual" : "Company Owner",
    //     companyType: null,
    //     businessName: "",
    //     businessEmail: "",
    //     businessPhoneNo: "",
    //     businessRegNo: "",
    //     address: {
    //       complexName: "",
    //       buildingName: "",
    //       city: "",
    //       streetNo: "",
    //       street: "",
    //       suburb: "",
    //       province: "",
    //       zipCode: "",
    //     },
    //     rmiNo: "",
    //     emailNotification: false,
    //     pushNotification: false,
    //     whatsAppNotification: false,
    //     vatNo: "",
    //   };
  }
  async submit() {
    if (this.reference) {
      // this is then edit profile
      const update = await UserLib.updateProfile(this.form);
      NotificationLib.createSuccessNotification("Profile updated");
    } else {
      // this is registration on auth
      const registration = await UserLib.register(this.form);
      //console.log("Registration", registration);
      this.reset(this.type);
      NotificationLib.createSuccessNotification("Registration successful");
      return Promise.resolve();
    }
  }
  initForm(type: "personal" | "business", profile?: Profile) {
    return {
      name: profile ? profile.userDetails.nameOfUser : null,
      password: null,
      email: profile ? profile.userDetails.email : null,
      phoneNumber: profile ? profile.userDetails.phoneNumber : null,
      logo: profile
        ? type == "personal"
          ? null
          : profile.companyDetails && profile.companyDetails.logo
          ? profile.companyDetails.logo
          : new Image().toValue()
        : new Image().toValue(),
      userType: profile
        ? profile.userDetails.userType
        : type == "personal"
        ? "Individual"
        : "Company Owner",
      companyType:
        profile && profile.companyDetails
          ? profile.companyDetails.companyType
          : type == "personal"
          ? null
          : 1,
      businessName:
        profile && profile.companyDetails
          ? profile.companyDetails.businessName
          : "",
      businessEmail:
        profile && profile.companyDetails
          ? profile.companyDetails.businessEmail
          : "",
      businessPhoneNo:
        profile && profile.companyDetails
          ? profile.companyDetails.businessPhoneNo
          : "",
      businessRegNo:
        profile && profile.companyDetails
          ? profile.companyDetails.businessRegNo
          : "",
      address: profile
        ? profile.companyDetails
          ? {
              complexName: profile.companyDetails.businessComplexName,
              buildingName: profile.companyDetails.businessBuildingName,
              city: profile.companyDetails.businessCity,
              streetNo: profile.companyDetails.businessStreetNo,
              street: profile.companyDetails.businessStreet,
              suburb: profile.companyDetails.businessSuburb,
              province: profile.companyDetails.businessProvince,
              zipCode: profile.companyDetails.businessZipCode,
            }
          : {
              complexName: "",
              buildingName: "",
              city: "",
              streetNo: "",
              street: "",
              suburb: "",
              province: profile.userDetails.address,
              zipCode: "",
            }
        : {
            complexName: "",
            buildingName: "",
            city: "",
            streetNo: "",
            street: "",
            suburb: "",
            province: "",
            zipCode: "",
          },
      rmiNo:
        profile && profile.companyDetails ? profile.companyDetails.rmiNo : "",
      emailNotification: profile
        ? profile.userDetails.emailNotification
        : false,
      pushNotification: profile ? profile.userDetails.pushNotification : false,
      whatsAppNotification: profile
        ? profile.userDetails.whatsAppNotification
        : false,
      vatNo:
        profile && profile.companyDetails ? profile.companyDetails.vatNo : "",
    };
  }
}

class VehicleTypesForm extends Form {
  public form: VehicleTypes;
  public unchanged = true;
  public vehicleType?: VehicleTypes;
  constructor(vehicleType?: VehicleTypes) {
    super();
    this.unchanged = true;
    this.form = this.initForm(vehicleType);
    this.vehicleType = vehicleType;
  }
  reset() {
    this.unchanged = true;
    this.form = this.initForm(this.vehicleType);
  }
  initForm(vehicleType?: VehicleTypes) {
    return {
      category: vehicleType ? vehicleType.category : null,
      createdAt: vehicleType
        ? vehicleType.createdAt
        : dayjs().format("YYYY-MM-DD"),
      make: vehicleType ? vehicleType.make : null,
      model: vehicleType ? vehicleType.model : null,
      objectID: vehicleType ? vehicleType.objectID : null,
      uPdatedAt: vehicleType
        ? vehicleType.uPdatedAt
        : dayjs().format("YYYY-MM-DD"),
      year: vehicleType ? vehicleType.year : null,
    };
  }
  compare() {
    if (this.vehicleType == undefined) {
      this.unchanged = true;
      return;
    }
    if (this.form && this.vehicleType) {
      if (
        this.vehicleType.category == this.form.category &&
        this.vehicleType.make == this.form.make &&
        this.vehicleType.model == this.form.model &&
        this.vehicleType.year == this.form.year
      ) {
        //lodash isEqual perhaps?
        this.unchanged = true;
        //console.log("Has changes -> same", this.unchanged);
        return;
      } else {
        this.unchanged = false;
      }
    }
    return this.unchanged;
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.vehicleType) {
      // edit
      // const res = await editFunction
      if (this.vehicleType && this.form) {
        await vehicleTypeLib.UpdateNewUserEnteredDataToList({
          ...this.vehicleType,
          ...this.form,
        });
        NotificationLib.createSuccessNotification("Vehicle Type updated");
      }
    } else {
      // add
      if (this.form) {
        await vehicleTypeLib.AddVehicleToList(this.form);
        NotificationLib.createSuccessNotification("Vehicle Type Added");
      }
    }
    return Promise.resolve();
  }
}

class VehicleCategoriesForm extends Form {
  public form: VehicleCategories;
  public unchanged = true;
  public vehicleCategory?: VehicleCategories;
  constructor(vehicleCategory?: VehicleCategories) {
    super();
    this.unchanged = true;
    this.form = this.initForm(vehicleCategory);
    this.vehicleCategory = vehicleCategory;
  }
  reset() {
    this.unchanged = true;
    this.form = this.initForm(this.vehicleCategory);
  }
  initForm(vehicleCategory?: VehicleCategories): VehicleCategories {
    return {
      category: vehicleCategory ? vehicleCategory.category : null,
    };
  }
  compare() {
    if (this.vehicleCategory == undefined) {
      //console.log("Vehicle Category undef", this.vehicleCategory);
      this.unchanged = true;
      return;
    }
    if (this.form && this.vehicleCategory) {
      if (this.vehicleCategory.category == this.form.category) {
        //lodash isEqual perhaps?
        this.unchanged = true;
        //console.log("Has changes -> same", this.unchanged);
        return;
      } else {
        this.unchanged = false;
      }
    }
    return this.unchanged;
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.vehicleCategory) {
      // edit
      // const res = await editFunction
      if (this.vehicleCategory && this.form) {
        await vehicleTypeLib.UpdateVehicleCategories({
          ...this.vehicleCategory,
          ...this.form,
        });
        NotificationLib.createSuccessNotification("Vehicle Category updated");
      }
    } else {
      // add
      if (this.form) {
        await vehicleTypeLib.AddVehicleCategories(this.form);
        NotificationLib.createSuccessNotification("Vehicle Category Added");
      }
    }
    return Promise.resolve();
  }
}

class VideoCategoriesForm extends Form {
  public form: VehicleCategories;
  public unchanged = true;
  public videoCategory?: VehicleCategories;
  constructor(videoCategory?: VehicleCategories) {
    super();
    this.unchanged = true;
    this.form = this.initForm(videoCategory);
    this.videoCategory = videoCategory;
  }
  reset() {
    this.unchanged = true;
    this.form = this.initForm(this.videoCategory);
  }
  initForm(videoCategory?: VehicleCategories): VehicleCategories {
    return {
      category: videoCategory ? videoCategory.category : null,
    };
  }
  compare() {
    if (this.videoCategory == undefined) {
      //console.log("Video Category undef", this.videoCategory);
      this.unchanged = true;
      return;
    }
    if (this.form && this.videoCategory) {
      if (this.videoCategory.category == this.form.category) {
        //lodash isEqual perhaps?
        this.unchanged = true;
        //console.log("Has changes -> same", this.unchanged);
        return;
      } else {
        this.unchanged = false;
      }
    }
    return this.unchanged;
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.videoCategory) {
      // edit
      // const res = await editFunction
      if (this.videoCategory && this.form) {
        await videosHook.UpdateVideoCategories({
          ...this.videoCategory,
          ...this.form,
        });
        NotificationLib.createSuccessNotification("Video Category updated");
      }
    } else {
      // add
      if (this.form) {
        await videosHook.AddVideoCategories(this.form);
        NotificationLib.createSuccessNotification("Video Category Added");
      }
    }
    return Promise.resolve();
  }
}

class ProductCategoriesForm extends Form {
  public form: ProductCategories;
  public unchanged = true;
  public productCategory?: ProductCategories;
  constructor(productCategory?: ProductCategories) {
    super();
    this.unchanged = true;
    this.form = this.initForm(productCategory);
    this.productCategory = productCategory;
  }
  reset() {
    this.unchanged = true;
    this.form = this.initForm(this.productCategory);
  }
  initForm(productCategory?: ProductCategories): ProductCategories {
    return {
      category: productCategory ? productCategory.category : null,
    };
  }
  compare() {
    if (this.productCategory == undefined) {
      // console.log("Video Category undef", this.productCategory);
      this.unchanged = true;
      return;
    }
    if (this.form && this.productCategory) {
      if (this.productCategory.category == this.form.category) {
        //lodash isEqual perhaps?
        this.unchanged = true;
        //console.log("Has changes -> same", this.unchanged);
        return;
      } else {
        this.unchanged = false;
      }
    }
    return this.unchanged;
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.productCategory) {
      // edit
      // const res = await editFunction
      if (this.productCategory && this.form) {
        await storeHook.UpdateProductCategories({
          ...this.productCategory,
          ...this.form,
        });
        NotificationLib.createSuccessNotification("Product Category updated");
      }
    } else {
      // add
      if (this.form) {
        await storeHook.AddProductCategories(this.form);
        NotificationLib.createSuccessNotification("Product Category Added");
      }
    }
    return Promise.resolve();
  }
}

class EventCategoriesForm extends Form {
  public form: EventCategories;
  public unchanged = true;
  public eventCategory?: EventCategories;
  constructor(eventCategory?: EventCategories) {
    super();
    this.unchanged = true;
    this.form = this.initForm(eventCategory);
    this.eventCategory = eventCategory;
  }
  reset() {
    this.unchanged = true;
    this.form = this.initForm(this.eventCategory);
  }
  initForm(eventCategory?: EventCategories): EventCategories {
    return {
      category: eventCategory ? eventCategory.category : "",
    };
  }
  compare() {
    if (this.eventCategory == undefined) {
      //console.log("Event Category undef", this.eventCategory);
      this.unchanged = true;
      return;
    }
    if (this.form && this.eventCategory) {
      if (this.eventCategory.category == this.form.category) {
        //lodash isEqual perhaps?
        this.unchanged = true;
        // console.log("Has changes -> same", this.unchanged);
        return;
      } else {
        this.unchanged = false;
      }
    }
    return this.unchanged;
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.eventCategory) {
      // edit
      // const res = await editFunction
      if (this.eventCategory && this.form) {
        await eventsHook.UpdateEventCategories({
          ...this.eventCategory,
          ...this.form,
        });
        NotificationLib.createSuccessNotification("Event Category updated");
      }
    } else {
      // add
      if (this.form) {
        await eventsHook.AddEventCategories(this.form);
        NotificationLib.createSuccessNotification("Event Category Added");
      }
    }
    return Promise.resolve();
  }
}

class UserForm extends Form {
  public form: UserFormModel;
  public userId: string | null = null;
  public unchanged = true;
  public user?: UserFormModel;
  constructor(userId: string | null, user?: UserFormModel) {
    super();
    this.unchanged = true;
    this.form = this.initForm(user);
    this.user = user;
    this.userId = userId;
  }
  reset() {
    this.unchanged = true;
    this.form = this.initForm(this.user);
  }
  initForm(user?: UserFormModel) {
    return {
      name: user ? user.name : null,
      userEmail: user ? user.userEmail : null,
      userProvince: user ? user.userProvince : null,
      phoneNumber: user ? user.phoneNumber : null,
      businessName: user ? user.businessName : null,
      rMINo: user ? user.rMINo : null,
      businessRegNo: user ? user.businessRegNo : null,
      vATNo: user ? user.vATNo : null,
      businessPhoneNumber: user ? user.businessPhoneNumber : null,
      businessEmail: user ? user.businessEmail : null,
      userType: user ? user.userType : null,
      businessAddress: {
        complexName: user ? user.businessAddress.complexName : null,
        buildingName: user ? user.businessAddress.buildingName : null,
        streetNo: user ? user.businessAddress.streetNo : null,
        street: user ? user.businessAddress.street : null,
        suburb: user ? user.businessAddress.suburb : null,
        city: user ? user.businessAddress.city : null,
        zipCode: user ? user.businessAddress.zipCode : null,
        province: user ? user.businessAddress.province : null,
      },
      emailNotification: user ? user.emailNotification : false,
      pushNotification: user ? user.pushNotification : false,
      whatsAppNotficication: user ? user.whatsAppNotficication : false,
      companyId: user ? user.companyId : null,
      companyTypeId: user ? user.companyTypeId : null,
      password: null,
      logo: null,
      isAdmin: user ? user.isAdmin : null,
    };
  }
  compare() {
    if (this.user == undefined) {
      this.unchanged = true;
      return;
    }
    if (this.form && this.user) {
      if (
        this.user.businessAddress.buildingName ==
          this.form.businessAddress.buildingName &&
        this.user.businessAddress.city == this.form.businessAddress.city &&
        this.user.businessAddress.complexName ==
          this.form.businessAddress.complexName &&
        this.user.businessAddress.province ==
          this.form.businessAddress.province &&
        this.user.businessAddress.street == this.form.businessAddress.street &&
        this.user.businessAddress.streetNo ==
          this.form.businessAddress.streetNo &&
        this.user.businessAddress.suburb == this.form.businessAddress.suburb &&
        this.user.businessAddress.zipCode ==
          this.form.businessAddress.zipCode &&
        this.user.businessEmail == this.form.businessEmail &&
        this.user.businessName == this.form.businessName &&
        this.user.businessPhoneNumber == this.form.businessPhoneNumber &&
        this.user.businessRegNo == this.form.businessRegNo &&
        this.user.companyId == this.form.companyId &&
        this.user.companyTypeId == this.form.companyTypeId &&
        this.user.emailNotification == this.form.emailNotification &&
        this.user.name == this.form.name &&
        this.user.phoneNumber == this.form.phoneNumber &&
        this.user.pushNotification == this.form.pushNotification &&
        this.user.rMINo == this.form.rMINo &&
        this.user.userEmail == this.form.userEmail &&
        this.user.userProvince == this.form.userProvince &&
        this.user.userType == this.form.userType &&
        this.user.vATNo == this.form.vATNo &&
        this.user.whatsAppNotficication == this.form.whatsAppNotficication &&
        this.user.password == this.form.password &&
        this.user.isAdmin == this.form.isAdmin
      ) {
        //lodash isEqual perhaps?
        this.unchanged = true;
        //  console.log("Has changes -> same", this.unchanged);
        return;
      } else {
        this.unchanged = false;
      }
    }
    return this.unchanged;
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.user) {
      // edit
      // const res = await editFunction
      if (this.user && this.form) {
        const data = { id: this.userId, ...this.form };
        const updateResp = await UserLib.UpdateUserWithId(data);
        NotificationLib.createSuccessNotification("User Updated");
      }
    } else {
      // add
      if (this.form) {
        const registration: Registration = {
          address: {
            city: this.form.businessAddress.city,
            province: this.form.businessAddress.province,
            street: this.form.businessAddress.street,
            streetNo: this.form.businessAddress.streetNo,
            suburb: this.form.businessAddress.suburb,
            zipCode: this.form.businessAddress.zipCode,
            buildingName: this.form.businessAddress.buildingName,
            complexName: this.form.businessAddress.complexName,
          },
          businessEmail: this.form.businessEmail ? this.form.businessEmail : "",
          businessName: this.form.businessName ? this.form.businessName : "",
          businessPhoneNo: this.form.businessPhoneNumber
            ? this.form.businessPhoneNumber
            : "",
          businessRegNo: this.form.businessRegNo ? this.form.businessRegNo : "",
          companyType: this.form.companyTypeId,
          email: this.form.userEmail,
          emailNotification: this.form.emailNotification,
          logo: this.form.logo,
          name: this.form.name,
          password: this.form.password,
          phoneNumber: this.form.phoneNumber,
          pushNotification: this.form.pushNotification,
          rmiNo: this.form.rMINo ? this.form.rMINo : "",
          userType: this.form.userType ? this.form.userType : "",
          vatNo: this.form.vATNo ? this.form.vATNo : "",
          whatsAppNotification: this.form.whatsAppNotficication,
          id: null,
        };
        const registrationResp = await UserLib.register(registration);
        //console.log("Registration", registrationResp);
        NotificationLib.createSuccessNotification("User Added");
        return Promise.resolve();
      }
    }
    return Promise.resolve();
  }
}

class EventsForm extends Form {
  public form: Event;
  public unchanged = true;
  public event?: Event;
  constructor(event?: Event) {
    super();
    this.unchanged = true;
    this.form = this.initForm(event);
    this.event = event;
  }
  reset() {
    this.unchanged = true;
    this.form = this.initForm(this.event);
  }
  initForm(event?: Event) {
    return {
      eventTitle: event ? event.eventTitle : null,
      description: event ? event.description : null,
      start: event ? event.start : null,
      ending: event ? event.ending : null,
      _id: event ? event._id : null,
      createdBy: event ? event.createdBy : null,
      createdDate: event ? event.createdDate : null,
      updatedBy: event ? event.updatedBy : null,
      updatedDate: event ? event.updatedDate : null,
      deletedDate: event ? event.deletedDate : null,
      category: event ? event.category : "",
      location: event ? event.location : "",
      url: event ? event.url : "",
    };
  }
  compare() {
    if (this.event == undefined) {
      this.unchanged = true;
      return;
    }
    if (this.form && this.event) {
      if (
        this.event.eventTitle == this.form.eventTitle &&
        this.event.description == this.form.description &&
        this.event.start == this.form.start &&
        this.event.ending == this.form.ending &&
        this.event.location == this.form.location &&
        this.event.url == this.form.url &&
        this.event.category == this.form.category
      ) {
        //lodash isEqual perhaps?
        this.unchanged = true;
        //console.log("Has changes -> same", this.unchanged);
        return;
      } else {
        this.unchanged = false;
      }
    }
    return this.unchanged;
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.event) {
      // edit
      // const res = await editFunction
      if (this.event && this.form) {
        await eventsHook.UpdateEventCalendar({
          ...this.event,
          ...this.form,
        });
        NotificationLib.createSuccessNotification("Event updated");
      }
    } else {
      // add
      if (this.form) {
        const temp: Event_CREATE = {
          description: this.form.description,
          ending: this.form.ending,
          eventTitle: this.form.eventTitle,
          start: this.form.start,
          category: this.form.category,
          location: this.form.location,
          url: this.form.url,
        };
        await eventsHook.CreateEventCalendar(temp);
        NotificationLib.createSuccessNotification("Event Added");
      }
    }
    return Promise.resolve();
  }
}

class VideosForm extends Form {
  public form: Video;
  public unchanged = true;
  public video?: Video;
  constructor(video?: Video) {
    super();
    this.unchanged = true;
    this.form = this.initForm(video);
    this.video = video;
  }
  reset() {
    this.unchanged = true;
    this.form = this.initForm(this.video);
  }
  initForm(video?: Video) {
    return {
      id: video ? video.id : 0,
      videoUrl: video ? video.videoUrl : "",
      title: video ? video.title : "",
      description: video ? video.description : "",
      status: video ? video.status : 0,
      createByUser: video ? video.createByUser : "",
      createDate: video ? video.createDate : "",
      updateByUser: video ? video.updateByUser : "",
      updateDate: video ? video.updateDate : "",
      deleted: video ? video.deleted : false,
      category: video ? video.category : "",
    };
  }
  compare() {
    if (this.video == undefined) {
      this.unchanged = true;
      return;
    }
    if (this.form && this.video) {
      if (
        this.video.videoUrl == this.form.videoUrl &&
        this.video.title == this.form.title &&
        this.video.description == this.form.description &&
        this.video.status == this.form.status &&
        this.video.category == this.form.category
      ) {
        //lodash isEqual perhaps?
        this.unchanged = true;
        // console.log("Has changes -> same", this.unchanged);
        return;
      } else {
        this.unchanged = false;
      }
    }
    return this.unchanged;
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.video) {
      // edit
      // const res = await editFunction
      if (this.video && this.form) {
        await videosHook.UpdateVideo({
          ...this.video,
          ...this.form,
        });
        NotificationLib.createSuccessNotification("Video updated");
      }
    } else {
      // add
      if (this.form) {
        this.form.status = 0;
        await videosHook.CreateVideo(this.form);
        NotificationLib.createSuccessNotification("Video Added");
      }
    }
    return Promise.resolve();
  }
}

class ProductsForm extends Form {
  public form: ProductsViewModel;
  public unchanged = true;
  public product?: ProductsViewModel;
  constructor(product?: ProductsViewModel) {
    super();
    this.unchanged = true;
    this.form = this.initForm(product);
    this.product = product;
  }
  reset() {
    this.unchanged = true;
    this.product = this.initForm(this.product);
  }
  initForm(product?: ProductsViewModel) {
    return {
      id: product ? product.id : 0,
      title: product ? product.title : "",
      description: product ? product.description : "",
      category: product ? product.category : "",
      price: product ? (product.price.toFixed(2) as any) : 0,
      images: product ? product.images : [],
      status: product ? product.status : undefined,
      user: product ? product.user : null,
    };
  }
  compare() {
    if (this.product == undefined) {
      this.unchanged = true;
      return;
    }
    if (this.form && this.product) {
      if (
        this.product.price == this.form.price &&
        this.product.title == this.form.title &&
        this.product.description == this.form.description &&
        this.product.category == this.form.category &&
        this.product.images.length == this.form.images.length
      ) {
        //lodash isEqual perhaps?
        this.unchanged = true;
        //console.log("Has changes -> same", this.unchanged);
        return;
      } else {
        this.unchanged = false;
      }
    }
    return this.unchanged;
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.product) {
      // edit
      // const res = await editFunction
      if (this.product && this.form) {
        await storeHook.UpdateProducts({
          ...this.product,
          ...this.form,
        });
        NotificationLib.createSuccessNotification("Product updated");
      }
    } else {
      // add
      if (this.form) {
        await storeHook.AddProduct(this.form);
        NotificationLib.createSuccessNotification("Product Added");
      }
    }
    return Promise.resolve();
  }
}

class PushNotifsForm extends Form {
  public form: AdminNotificationModel;
  public unchanged = true;
  public notification?: AdminNotificationModel;
  constructor(notification?: AdminNotificationModel) {
    super();
    this.unchanged = true;
    this.form = this.initForm(notification);
    this.notification = notification;
  }
  reset() {
    this.unchanged = true;
    this.form = this.initForm(this.notification);
  }
  initForm(notification?: AdminNotificationModel): AdminNotificationModel {
    return {
      id: notification ? notification.id : undefined,
      title: notification ? notification.title : "",
      message: notification ? notification.message : "",
      createDate: notification ? notification.createDate : "",
      updatedBy: notification ? notification.updatedBy : "",
      updatedDate: notification ? notification.updatedDate : undefined,
      deletedBy: notification ? notification.deletedBy : undefined,
      deletedDate: notification ? notification.deletedDate : undefined,
      createBy: notification ? notification.createBy : undefined,
      isRecurring: notification ? notification.isRecurring : undefined,
      notificationStartDate: notification
        ? notification.notificationStartDate
        : "",
      numberOfrecurringDays: notification
        ? notification.numberOfrecurringDays
        : undefined,
      notificationSent: notification
        ? notification.notificationSent
        : undefined,
    };
  }
  compare() {
    if (this.notification == undefined) {
      this.unchanged = true;
      return;
    }
    if (this.form && this.notification) {
      if (
        this.notification.title == this.form.title &&
        this.notification.message == this.form.message &&
        this.notification.isRecurring == this.form.isRecurring &&
        this.notification.notificationStartDate ==
          this.form.notificationStartDate &&
        this.notification.numberOfrecurringDays ==
          this.form.numberOfrecurringDays
      ) {
        //lodash isEqual perhaps?
        this.unchanged = true;
        //console.log("Has changes -> same", this.unchanged);
        return;
      } else {
        this.unchanged = false;
      }
    }
    return this.unchanged;
  }
  async submit() {
    // having a jobcard  === edit || === add
    if (this.notification) {
      // edit
      // const res = await editFunction
      if (this.notification && this.form) {
        await adminNotificationsHook.UpdateAdminNotification({
          ...this.notification,
          ...this.form,
        });
        NotificationLib.createSuccessNotification("Push Notification updated");
      }
    } else {
      // add
      if (this.form) {
        const temp: AdminNotificationModel_CREATE = {
          title: this.form.title,
          message: this.form.message,
          isRecurring: this.form.isRecurring,
          notificationStartDate: this.form.notificationStartDate,
          numberOfrecurringDays: this.form.numberOfrecurringDays,
        };
        await adminNotificationsHook.AddAdminNotification(temp);
        NotificationLib.createSuccessNotification("Push Notification Added");
      }
    }
    return Promise.resolve();
  }
}

export {
  Form,
  UserRegisterForm,
  VehicleTypesForm,
  UserForm,
  EventsForm,
  EventCategoriesForm,
  VideosForm,
  VehicleCategoriesForm,
  VideoCategoriesForm,
  ProductsForm,
  ProductCategoriesForm,
  PushNotifsForm,
};
