
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/store/modules/Loader";
import Nav from "@/store/modules/Nav";
import { registerHook } from "@/helpers/register";
import { directoryHook } from "@/helpers/directory";
import { Company } from "@/models/company";
import { NotificationLib } from "@/helpers";

interface ComapnyTableViewModel extends Company {
  companyTypeStr: string | number | null;
}

@Component({})
export default class Directory extends Vue {
  items: ComapnyTableViewModel[] = [];
  search = "";
  sortDesc = false;
  sortBy = "businessName";
  modal = false;
  approvalItem = {} as Company;
  headers = [
    { text: "Company Name", value: "businessName" },
    { text: "Company Type", value: "companyTypeStr" },
    { text: "Email", value: "businessEmail" },
    { text: "Phone No.", value: "businessPhoneNo" },
    { text: "Approved", value: "approved" },
    { text: "", value: "actions", sortable: false },
  ];
  keys = [
    { text: "Company Name", value: "businessName" },
    { text: "Company Type", value: "companyTypeStr" },
    { text: "Email", value: "businessEmail" },
    { text: "Phone No.", value: "businessPhoneNo" },
    { text: "Approved", value: "approved" },
    { text: "Status", value: "" },
  ];

  openApprovalModal(i: Company): void {
    this.approvalItem = i;
    this.modal = true;
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  getCompanyTypeDesc(companyType: number | null): number | string | null {
    //console.log("SystemCompany Types: ", Nav.getSystemCompanyTypes);
    var result = Nav.getSystemCompanyTypes.find(
      (elem) => elem.id == companyType
    );
    if (result) {
      return result.description;
    } else {
      return companyType;
    }
  }

  async updateApprovalStatus(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      this.modal = false;
      this.approvalItem.approved = !this.approvalItem.approved;
      const res = await directoryHook.ApproveMechanic(this.approvalItem);
      NotificationLib.createSuccessNotification(
        "Company" + this.approvalItem.approved ? "Approved" : "Rejected."
      );

      await this.init();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }

  async init(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      const res = await registerHook.GetAllCompanyAsync();
      // console.log("Companies :", res);
      this.items = [];
      res.map((elem) => {
        this.items.push({
          approved: elem.approved,
          businessBuildingName: elem.businessBuildingName,
          businessCity: elem.businessCity,
          businessComplexName: elem.businessComplexName,
          businessEmail: elem.businessEmail,
          businessName: elem.businessName,
          businessPhoneNo: elem.businessPhoneNo,
          businessProvince: elem.businessProvince,
          businessRegNo: elem.businessRegNo,
          businessStreet: elem.businessStreet,
          businessStreetNo: elem.businessStreetNo,
          businessSuburb: elem.businessSuburb,
          businessZipCode: elem.businessZipCode,
          companyType: elem.companyType,
          companyTypeStr: this.getCompanyTypeDesc(elem.companyType),
          id: elem.id,
          logo: elem.logo,
          rmiNo: elem.rmiNo,
          vatNo: elem.vatNo,
        });
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }
}
