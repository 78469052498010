
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import { PushNotifsForm } from "@/helpers/classes/Form";
import { adminNotificationsHook } from "@/helpers/adminNotifications";
import { AdminNotificationModel } from "@/models/adminNotifications";

@Component({
  filters: {
    displayDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    },
  },
})
export default class PushNotificationModal extends Vue {
  @PropSync("open") modal!: boolean;
  @Prop() readonly notification!: AdminNotificationModel | undefined;
  form: PushNotifsForm | null = null;
  startDateMenu = false;
  startDate = dayjs().format("YYYY-MM-DD");
  startTimeMenu = false;
  startTime = dayjs().format("HH:mm");
  valid = false;
  loading = false;
  rules = {
    mileage: (v: string) => v == null || Number(v) >= 0 || "Cannot be empty",
    required: (v: string) => !!v || "Cannot be empty",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
    minFileSize: (value: { size: number }): boolean | string =>
      !value || value.size > 146484.375 || "Min File Dimensions are 500x300",
    vin: (value: { length: number }): boolean | string =>
      !value ||
      value.length == 17 ||
      "Standard VIN numbers are 17 characters long",
    requiredMultiple: (v: string[]): boolean | string =>
      !v || v.length > 0 || "At least one option required.",
  };

  @Watch("open")
  async onOpenChanged(val: boolean) {
    if (val == true) {
      this.form = new PushNotifsForm(this.notification);
      this.notification
        ? (this.form.form.notificationStartDate = dayjs(
            this.notification.notificationStartDate
          ).format("YYYY-MM-DD"))
        : (this.form.form.notificationStartDate = dayjs().format("YYYY-MM-DD"));
      //this.dateChanged();
      if (this.notification) {
        /* this.startDate = dayjs(this.event.start).format("YYYY-MM-DD");
        this.startTime = dayjs(this.event.start).format("HH:mm");
        this.endDate = dayjs(this.event.ending).format("YYYY-MM-DD");
        this.endTime = dayjs(this.event.ending).format("HH:mm"); */
      }
    } else {
      this.close();
    }
  }

  @Watch("form.form", {
    deep: true,
  })
  onFormChanges() {
    //console.log("Has changes");
    if (this.form && this.form.notification) {
      this.form.compare();
    }
  }

  /*  @Watch("startDate", { immediate: true })
  dateChanged() {
    if (this.form) {
      this.form.form.start = this.startDate + "T" + this.startTime;
      //console.log("this.form.form.start", this.form.form.start);
    }
  } */

  close(): void {
    /* this.startDate = dayjs().format("YYYY-MM-DD");
    this.endDate = dayjs().add(1, "day").format("YYYY-MM-DD");
    this.startTime = dayjs().format("HH:mm");
    this.endTime = dayjs().add(1, "hour").format("HH:mm"); */
    this.$emit("close");
    this.modal = false;
  }

  get getUnchanged() {
    return this.form ? this.form.unchanged : true;
  }

  async submit(): Promise<void> {
    try {
      this.loading = true;
      if (this.form) {
        await this.form.submit();
      }
      this.close();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
      this.$emit("refresh");
    }
  }
}
