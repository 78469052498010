
import Loader from "@/store/modules/Loader";
import { dashboardHook } from "@/helpers/dashboard";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Dashboard extends Vue {
  dashboardStats: {
    title: string;
    field: string;
    value: number;
    icon: string | null;
    iconURL: string | null;
  }[] = [];

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      const res = await dashboardHook.GetDashboardStats();
      this.dashboardStats = [];
      this.dashboardStats.push({
        title: "Pending Videos",
        field: "totalPendingVideos",
        value: res.totalPendingVideos,
        icon: null,
        iconURL:
          "https://img.icons8.com/ios-filled/55/FFFFFF/video-gallery.png",
      });
      this.dashboardStats.push({
        title: "Registered Companies",
        field: "totalRegisteredCompanies",
        value: res.totalRegisteredCompanies,
        icon: null,
        iconURL:
          "https://img.icons8.com/ios-glyphs/55/FFFFFF/conference--v1.png",
      });
      this.dashboardStats.push({
        title: "Registered Users",
        field: "totalRegisteredUsers",
        value: res.totalRegisteredUsers,
        icon: null,
        iconURL:
          "https://img.icons8.com/external-tanah-basah-glyph-tanah-basah/55/FFFFFF/external-user-user-tanah-basah-glyph-tanah-basah-4.png",
      });
      this.dashboardStats.push({
        title: "Vehicle Makes",
        field: "totalVehicleMakes",
        value: res.totalVehicleMakes,
        icon: null,
        iconURL:
          "https://img.icons8.com/sf-black-filled/55/FFFFFF/traffic-jam.png",
      });
      this.dashboardStats.push({
        title: "User Vehicles",
        field: "totalVehicles",
        value: res.totalVehicles,
        icon: null,
        iconURL:
          "https://img.icons8.com/fluency-systems-filled/60/FFFFFF/porsche-911-new.png",
      });
      this.dashboardStats.push({
        title: "Videos",
        field: "totalVideos",
        value: res.totalVideos,
        icon: null,
        iconURL: "https://img.icons8.com/ios-filled/50/FFFFFF/video.png",
      });

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }
}
