import axios from "axios";
import Auth from "@/store/modules/Auth";
import { NotificationLib, StorageLib, UserLib } from "@/helpers";
import router from "@/router";
import dayjs from "dayjs";

const axiosNoAuth = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
// Only after signed in
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

interface ServerErrorData {
  status: string;
  message: string;
}

// Error handling
const generateMessageFromError = (error: unknown): string => {
  let message = "";
  if (axios.isAxiosError(error)) {
    //console.log("To json response ", error.toJSON(), error);
    if (error.response) {
      if (error.response.data && typeof error.response.data == "object") {
        // for our BE
        if (
          (error.response.data as ServerErrorData).status &&
          (error.response.data as ServerErrorData).message
        ) {
          message = (error.response.data as ServerErrorData).status + ": ";
          message += (error.response.data as ServerErrorData).message;
        } else if (
          (error.response.data as any).status &&
          (error.response.data as any).title
        ) {
          message = (error.response.data as any).status + ": ";
          message += (error.response.data as any).title + `<br />`;
          const errs = (error.response.data as any).errors;
          if (errs) {
            Object.entries(errs).forEach((e) => {
              message += e[1] + `<br />`;
            });
          }
        } else {
          message = JSON.stringify(error.response.data);
        }
      } else if (error.response.status && error.response.statusText) {
        // general error message
        //console.log("data if else ", error.response);
        message = error.response.status + ": ";
        message += error.response.statusText;
      } else {
        // root error
        //console.log("data else ", error);
        if (error.code) {
          message = error.code + ": ";
        } else {
          message = "Unknown: ";
        }
        message += error.message;
      }
    } else {
      //console.log("Hello logger ", error);
      if (error.code) message = error.code + ": ";
      message += error.message;
    }
  } else {
    if (error instanceof Error) {
      if (error.message) {
        message = error.message;
      } else {
        console.error("error non message", error);
      }
    } else if (typeof error === "object") {
      const temp = JSON.stringify(error);
      if (temp) {
        message = temp;
      } else {
        console.error("Couldnt stringify error", error);
        // message = error;
      }
    } else {
      message = "Unidentified error: " + error;
    }
  }
  if (message == "") {
    return "Uknown issue: Contact Support.";
  }
  return message;
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.warn(error);
    const message = generateMessageFromError(error);
    // console.log("Testing message :", message);
    NotificationLib.createErrorNotification(message);
    if (axios.isAxiosError(error)) {
      //console.log("Axios inter: ", error);
      if (error.response && error.response.status == 401) {
        Auth.setAuthToken(null);
        StorageLib.removeLogin();
      }
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(async (restConfig) => {
  /* console.log("Auth Token AXIOS", Auth.getAuthToken);
  console.log("AXIOS request", restConfig.url); */
  if (Auth.getAuthToken) {
    const u = Auth.isLoggedIn;
    if (u == true) {
      // is logged in
      // const token = Auth.getAuthToken.accessToken;
      // console.log("Acces token :", token, u, Auth);
      const config = restConfig;
      config.url;
      if (config.headers) {
        // check expiry
        const tok = UserLib.getParsedJwt(Auth.getAuthToken.accessToken);
        if (tok && tok.exp) {
          // const temp = dayjs.unix(Number(tok.iat)).add(2, "minute");
          // const valid = UserLib.validateJWTExp(temp.unix());
          const valid = UserLib.validateJWTExp(tok.exp);

          if (!valid) {
            // handle expired token
            NotificationLib.createWarningNotification(
              "Token expired. Please log in again."
            );
            router
              .push({
                name: "authentication",
                hash: "#login",
              })
              .catch((err) => console.error("Axios Interceptor Err: ", err));
          } else {
            config.headers.Authorization =
              Auth.getAuthToken.tokenType + " " + Auth.getAuthToken.accessToken;
          }
        }
      }

      return config;
    }
  } else {
    NotificationLib.createWarningNotification(
      "Token expired. Please log in again."
    );
    console.error("router.currentRoute.name: ", router.currentRoute.name);

    router
      .push({
        name: "authentication",
        hash: "#login",
      })
      .catch((err) => console.error("Axios Interceptor Err: ", err));
  }
  return restConfig;
});

export { axiosInstance, axiosNoAuth, generateMessageFromError };
