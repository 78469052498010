
import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import NavModule from "@/store/modules/Nav";
import { StorageLib } from "@/helpers";

@Component
export default class NavDrawer extends Vue {
  logoutModal = false;
  flipDrawer(trust: boolean): void {
    // console.log("Nav flip me please ", trust, this.appDrawer);
    NavModule.setAppDrawer(trust);
  }
  get appDrawer(): boolean {
    return NavModule.getAppDrawer;
  }

  get canCalendar() {
    if (
      AuthModule.getProfile &&
      AuthModule.getProfile.companyDetails &&
      AuthModule.getProfile.companyDetails.companyType
    ) {
      const temp = NavModule.getSystemCompanyTypes.find(
        (e) =>
          AuthModule.getProfile &&
          AuthModule.getProfile.companyDetails &&
          AuthModule.getProfile.companyDetails.companyType &&
          e.id == AuthModule.getProfile.companyDetails.companyType
      );
      //console.log("can calendar ", temp);
      if (temp && temp.description.includes("Mechanic")) return true;
    }

    return false;
  }

  login(): void {
    if (this.$route.name != "authentication") {
      this.$router.push({ name: "authentication", hash: "#login" });
    }
  }

  logout(): void {
    AuthModule.logout();
    StorageLib.removeLogin();
    this.logoutModal = false;
    this.flipDrawer(false);
    this.login();
  }

  get LoggedIn(): boolean {
    return AuthModule.isLoggedIn;
  }
}
