import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import AppBar from "@/components/AppBar.vue";
import NavDrawer from "../components/NavDrawer.vue";
import Footer from "@/components/Footer.vue";

// Authentication
import Authentication from "@/views/Authentication.vue";
import ResetPassword from "@/views/authentication/ResetPassword.vue";
import { NotificationLib, StorageLib, UserLib } from "@/helpers/index";
import { AuthToken } from "@/models/user";
import AuthModule from "@/store/modules/Auth";

//Views
import Dashboard from "@/views/Dashboard.vue";
import Directory from "@/views/Directory.vue";
import Events from "@/views/Events.vue";
import Store from "@/views/Store.vue";
import Users from "@/views/Users.vue";
import vehicles from "@/views/Vehicle.vue";
import vehicleTypes from "@/views/VehicleTypes.vue";
import Videos from "@/views/Videos.vue";
import PushNotifications from "@/views/PushNotifications.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: {
      name: "dashboard",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    components: {
      default: Dashboard,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
    },
  },
  {
    path: "/directory",
    name: "directory",
    components: {
      default: Directory,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
    },
  },
  {
    path: "/events",
    name: "events",
    components: {
      default: Events,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
    },
  },
  {
    path: "/store",
    name: "store",
    components: {
      default: Store,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
    },
  },
  {
    path: "/users",
    name: "users",
    components: {
      default: Users,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
    },
  },
  {
    path: "/vehicles-types",
    name: "vehicleTypes",
    components: {
      default: vehicleTypes,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
    },
  },
  {
    path: "/vehicles",
    name: "vehicles",
    components: {
      default: vehicles,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
    },
  },
  {
    path: "/videos",
    name: "videos",
    components: {
      default: Videos,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
    },
  },
  {
    path: "/authentication",
    name: "authentication",
    components: {
      default: Authentication,
      appBar: AppBar,
      nav: NavDrawer,
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    components: {
      default: ResetPassword,
    },
  },
  {
    path: "/push-notifications",
    name: "pushNotifications",
    components: {
      default: PushNotifications,
      footer: Footer,
      appBar: AppBar,
      nav: NavDrawer,
    },
  },
];

const router = new VueRouter({
  mode: "history" /* 
  base: process.env.BASE_URL, */,
  routes,
});

router.beforeEach(async (to, from, next) => {
  try {
    const resStorage = await StorageLib.getLogin();
    const resAuthModule = AuthModule.getAuthToken;
    let token: AuthToken | null = null;
    //console.log("Res StorageLib.getLogin", resStorage);
    //console.log("AuthModule.getAuthToken", resAuthModule);
    if (resStorage != null) token = resStorage;
    if (resAuthModule != null) token = resAuthModule;
    //console.log("Get Token", token);

    if (token !== null) {
      //console.log("Got Login");
      const valid = await UserLib.processNewJWT(token);
      // NotificationLib.createErrorNotification("Get JWT Token");
      //console.log("Auth Token Valid", valid);
      if (valid) {
        setTimeout(async () => {
          try {
            // NotificationLib.createErrorNotification("Get Profile");
            if (to.name != from.name) {
              // console.log("to.name != from.name");
              await UserLib.getProfile();
              // console.log("Got Profile");
            }

            return Promise.resolve();
          } catch (err) {
            // console.log("Did Not get Profile");
            UserLib.logout();
            router
              .push({
                name: "authentication",
                hash: "#login",
                query: {
                  redirectedFrom: to.fullPath,
                },
              })
              .catch();

            return Promise.reject(err);
            // NotificationLib.createErrorNotification("Profile Error Caught");
          }
          // console.log(res);
          // AuthModule.setProfile(res);
        }, 1000);
      } else {
        NotificationLib.createWarningNotification(
          "Token expired. Please log in again"
        );
        UserLib.logout();

        router
          .push({
            name: "authentication",
            hash: "#login",
            query: {
              redirectedFrom: to.fullPath,
            },
          })
          .catch();
      }
    }

    if (token == null && to.name != "authentication") {
      if (to.name != "dashboard") {
        NotificationLib.createWarningNotification("Please log in to continue");

        if (from.name != "authentication") {
          router
            .push({
              name: "authentication",
              hash: "#login",
              query: {
                redirectedFrom: to.fullPath,
              },
            })
            .catch();
        }
      }
    }
    // todo lock routes based on roles on them.
    //console.log("Route ", to, from);
    next();
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
});

export default router;
