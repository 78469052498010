import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import { notificationType } from "models";
import { DeviceInfo } from "@capacitor/device";

@Module({
  dynamic: true,
  namespaced: true,
  name: "NotificationSystem",
  store,
})
class NotificationSystem extends VuexModule {
  deviceInfo: DeviceInfo | null = null;
  firebaseToken: string | null = null;
  currentNotif: notificationType | null = null;
  notifQueue: notificationType[] = [];
  get getCurrentNotif(): notificationType | null {
    return this.currentNotif;
  }
  get getDeviceInfo(): DeviceInfo | null {
    return this.deviceInfo;
  }
  get getNotifQueue(): notificationType[] {
    return this.notifQueue;
  }
  @Mutation
  setDeviceInfo(token: DeviceInfo | null) {
    this.deviceInfo = token;
  }
  @Action
  addNotifAndTimeoutCheck(notification: notificationType): void {
    if (this.currentNotif != null) {
      this.addToQueue(notification);
    } else {
      this.setCurrentNotif(notification);
      if (notification.timeout != -1) {
        //   console.log("Not -1 ", notification)
        window.setTimeout(
          this.clearCurrentNotifWithCheck,
          notification.timeout + 300,
          notification
        );
      }
    }
  }
  @Mutation
  addToQueue(notification: notificationType) {
    if (
      this.notifQueue.find((e) => e.message == notification.message) == null
    ) {
      this.notifQueue.push(notification);
    }
  }
  @Mutation
  removeFromQueue(notification: notificationType) {
    this.notifQueue = this.notifQueue.filter((e) => e !== notification);
  }
  @Mutation
  setCurrentNotif(notification: notificationType | null) {
    this.currentNotif = notification;
  }
  // @Mutation
  // addNotification(notification: notificationType): void {
  //   if (this.currentNotif == null) {
  //     this.currentNotif = notification;
  //   } else {
  //     this.notifQueue.push(notification);
  //   }
  // }
  @Action
  clearCurrentNotifWithCheck(notif: notificationType): void {
    if (this.currentNotif == notif) {
      // console.log("Calling clear with check", notif);
      if (this.notifQueue.length > 0) {
        const nextNotif = this.notifQueue[0];
        this.removeFromQueue(nextNotif);
        this.setCurrentNotif(nextNotif);
        if (nextNotif.timeout != -1)
          window.setTimeout(
            this.clearCurrentNotifWithCheck,
            nextNotif.timeout + 300,
            nextNotif
          );
      } else {
        // console.log("notifqueue == 0");
        this.setCurrentNotif(null);
      }
    }
  }
  @Action
  clearCurrentNotif(): void {
    if (this.notifQueue.length > 0) {
      const nextNotif = this.notifQueue[0];
      this.removeFromQueue(nextNotif);
      this.setCurrentNotif(nextNotif);
    } else {
      this.setCurrentNotif(null);
    }
  }
  get getFirebaseToken(): string | null {
    return this.firebaseToken;
  }
  @Mutation
  setFirebaseToken(token: string | null) {
    this.firebaseToken = token;
  }
}
export default getModule(NotificationSystem);
