import _Vue from "vue";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
  isSupported,
} from "firebase/messaging";

export interface fbHookType {
  fbConfig: FirebaseOptions;
  getFbApp: () => FirebaseApp | null;
  getFbMessaging: () => Messaging | null;
  initApp: () => void;
  subscribeOnMessage: () => void;
  // fbAnalytics: Analytics;
  getTokenAsync: (
    serviceWorkerRegistration: ServiceWorkerRegistration
  ) => Promise<string>;
  isSupported: () => Promise<boolean>;
}
export const firebaseHook = (): fbHookType => {
  const firebaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyCCvSB8256diohbca2GU-N_wjDfD0i9-2A",
    authDomain: "push-notifications-890a6.firebaseapp.com",
    projectId: "push-notifications-890a6",
    storageBucket: "push-notifications-890a6.appspot.com",
    messagingSenderId: "775677585148",
    appId: "1:775677585148:web:ac86dde2f13c2bc2475979",
    measurementId: "G-TKYFG8HNJM",
  };

  let firebaseApp: FirebaseApp | null = null;
  let messaging: Messaging | null = null;

  const initApp = () => {
    firebaseApp = initializeApp(firebaseConfig);
    messaging = getMessaging(firebaseApp);
    //console.log("Init test: ", firebaseApp, messaging);
    onMessage(messaging, (payload) => {
      console.log("PLEASE: Message received. ", payload);
      // emit event here
    });
    //
  };

  const subscribeOnMessage = (): void => {
    const msg = getFbMessaging();
    if (msg == null) {
      console.error("Cannot subscribe to on message == null");
      return;
    }
    console.log("Do listening to message");
    onMessage(msg, (payload) => {
      console.log("PLEASE: Message received. ", payload);
      // emit event here
    });
  };
  // const analytics = getAnalytics(firebaseApp);
  const getFbApp = (): FirebaseApp | null => {
    return firebaseApp;
  };
  const getFbMessaging = (): Messaging | null => {
    return messaging;
  };

  const getTokenAsync = async (
    serviceWorkerRegistration: ServiceWorkerRegistration
  ): Promise<string> => {
    try {
      const msg = getFbMessaging();
      if (msg == null) {
        console.log("Firebase Messaging == null");
        return Promise.reject("Firebase Messaging == null");
      }
      //console.log("Going to get token");
      const token = await getToken(msg, {
        vapidKey:
          "BErMEjI1qXGOQO6Vvr7cm-dCE8538LOpA6Xcv5QPl77Q190IRuwPNOYg8ghmzsm_tGWlB8aiW823HScxPwvBVa0",
        serviceWorkerRegistration: serviceWorkerRegistration,
      }).catch((err) => {
        console.log("GET_TOKEN err: ", err);
        return Promise.reject(err);
      });

      if (token) {
        console.log("Registration Token Available", token);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
      return Promise.resolve(token);
    } catch (err) {
      console.log("GET_TOKEN_ASYNC err: ", err);
      return Promise.reject(err);
    }
  };

  return {
    fbConfig: firebaseConfig,
    getFbMessaging,
    getFbApp,
    initApp,
    subscribeOnMessage,
    // fbAnalytics: analytics,
    getTokenAsync,
    isSupported,
  };
};
const fbHook = firebaseHook();
export default fbHook;
