import { ImageFile } from "@/models/files";
import { fileToBase64 } from "@/utilities";

export class Image implements ImageFile {
  public fileName: string | null;
  public fileSize: number | null;
  public mimeType: string | null;
  public upload: string | null;
  constructor(file?: ImageFile) {
    if (!file) {
      this.fileName = "";
      this.fileSize = 0;
      this.mimeType = "";
      this.upload = "";
    } else {
      this.fileName = file.fileName;
      this.fileSize = file.fileSize;
      this.mimeType = file.mimeType;
      this.upload = file.upload;
    }
  }
  static async init(file?: File | ImageFile) {
    if (file instanceof File) {
      const img = (await fileToBase64(file)) as string;
      return new Image({
        fileName: file.name,
        fileSize: file.size,
        mimeType: file.type,
        upload: img.split(",")[1],
      }).toValue();
    } else if (file) {
      return new Image(file).toValue();
    } else {
      return new Image().toValue();
    }
  }
  toValue(): ImageFile {
    return {
      fileName: this.fileName,
      fileSize: this.fileSize,
      mimeType: this.mimeType,
      upload: this.upload,
    };
  }
}
