import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import {
  // UserMail,
  AuthToken,
  // UserName,
  User,
  Registration,
  Profile,
} from "@/models/user";
import { Business } from "@/models/business";
import { TokenInfo } from "@/models/authentication";

export interface login {
  email: string | null;
  password: string | null;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "Auth",
  store,
})
class Auth extends VuexModule {
  profile: Profile | null = null;
  get getProfile() {
    return this.profile;
  }
  @Mutation
  setProfile(user: any) {
    this.profile = user;
  }
  /*
   * AuthToken
   *
   * Stores the JWT authentication token.
   */
  authToken: null | AuthToken = null;
  get getAuthToken() {
    return this.authToken;
  }
  @Mutation
  setAuthToken(val: AuthToken | null) {
    //console.log("Setting Auth Token", val);
    this.authToken = val;
  }
  get getTokenInfo() {
    if (this.getAuthToken) {
      let tok = null;
      if (this.getAuthToken) {
        tok = this.getAuthToken.accessToken;
      } else {
        tok = null;
      }
      if (!tok) return null;
      const consumer: TokenInfo = JSON.parse(atob(tok.split(".")[1]));
      return consumer;
    }
    return null;
  }
  /*
   * Logout
   *
   * Log a user out.
   */
  @Action({ rawError: true })
  logout() {
    //console.log("Logout");
    this.setAuthToken(null);
    this.setProfile(null);
  }
  get isLoggedIn() {
    //console.log("Check Logged In", this.authToken);
    return this.authToken != null ? true : false;
  }
  /*
   * Business
   *
   * Extracts the business information out for the curent user
   */
  //get getBusiness(): Business {
  //  return {
  //    name: this.profile?.businessName ? this.profile?.businessName : "",
  //    email: this.profile?.businessEmail ? this.profile?.businessEmail : "",
  //    phoneNumber: this.profile?.businessPhoneNumber
  //      ? this.profile?.businessPhoneNumber
  //      : "",
  //    address: this.profile?.address ? this.profile?.address : "",
  //  };

  get getBusiness() {
    if (this.profile && this.profile.companyDetails) {
      return {
        name: this.profile.companyDetails.businessName,
        email: this.profile.companyDetails.businessEmail,
        phoneNumber: this.profile.companyDetails.businessPhoneNo,
        address: {
          buildingName: this.profile.companyDetails.businessBuildingName,
          street: this.profile.companyDetails.businessStreet,
          streetNo: this.profile.companyDetails.businessStreetNo,
          suburb: this.profile.companyDetails.businessSuburb,
          province: this.profile.companyDetails.businessProvince,
          city: this.profile.companyDetails.businessCity,
          zipCode: this.profile.companyDetails.businessZipCode,
        },
      };
    }
  }
}
export default getModule(Auth);
