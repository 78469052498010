
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import ImageAttachment from "@/components/fields/ImageAttachment.vue";
import Loader from "@/store/modules/Loader";
import { vehicleTypeLib } from "@/helpers/vehicleTypes";
import { VehicleTypes as VehicleTypesModel } from "@/models/vehicleTypes";
import { VehicleTypesForm } from "@/helpers/classes/Form";
import { VehicleCategories } from "@/models/vehicleCategories";

@Component({
  components: {
    ImageAttachment,
  },
})
export default class VehicleTypesModal extends Vue {
  @PropSync("open") modal!: boolean;
  @Prop() readonly vehicleType!: VehicleTypesModel | undefined;
  form: VehicleTypesForm | null = null;
  valid = false;
  loading = false;
  selectedCategory: string[] | null = null;
  vehicleCategories: VehicleCategories[] = [];
  rules = {
    mileage: (v: string) => v == null || Number(v) >= 0 || "Cannot be empty",
    required: (v: string) => !!v || "Cannot be empty",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
    minFileSize: (value: { size: number }): boolean | string =>
      !value || value.size > 146484.375 || "Min File Dimensions are 500x300",
    vin: (value: { length: number }): boolean | string =>
      !value ||
      value.length == 17 ||
      "Standard VIN numbers are 17 characters long",
    requiredMultiple: (v: string[]): boolean | string =>
      !v || v.length > 0 || "At least one option required.",
  };

  @Watch("selectedCategory")
  async onSelectedCategoryChanged(val: boolean) {
    if (this.selectedCategory && this.form) {
      this.form.form.category = this.selectedCategory.join(", ");
    }
  }
  @Watch("form.form", {
    deep: true,
  })
  onFormChanges() {
    //console.log("Has changes");
    if (this.form && this.form.vehicleType) {
      this.form.compare();
    }
    // this.unchanged = false;
  }

  async init(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      const res = await vehicleTypeLib.GetVehicleCategories();
      //console.log("Vehicles Categories:", res);
      this.vehicleCategories = res;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }

  @Watch("open")
  async onOpenChanged(val: boolean) {
    if (val == true) {
      await this.init();
      this.form = new VehicleTypesForm(this.vehicleType);
      if (this.form && this.form.form.category) {
        this.selectedCategory = this.form.form.category.split(", ");
      }
    } else {
      /*if (this.selectedMake) {
        this.selectedMake = null;
        this.makes = [];
      }
      if (this.selectedModel) {
        this.selectedModel = null;
        this.models = [];
      }
      if (this.selectedYear) {
        this.selectedYear = null;
        this.years = [];
      } */
      this.$emit("close");
    }
  }

  close(): void {
    this.$emit("close");
    this.modal = false;
  }

  get getUnchanged() {
    return this.form ? this.form.unchanged : true;
  }

  /* makesFilter(item: string, queryText: string): boolean {
    // console.log("Searched ", queryText, itemText, item);
    if (
      item != null &&
      item.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
    ) {
      return true;
    } else return false;
  } */

  /* modelsFilter(item: string, queryText: string): boolean {
    // console.log("Searched ", queryText, itemText, item);
    if (
      item != null &&
      item.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
    ) {
      return true;
    } else return false;
  } */

  /* async getAllVehicleMakes(): Promise<void> {
    try {
      //this.loading.makes = true;
      this.makes = await vehicleTypeLib.GetAllVehicleMakes();
      return Promise.resolve();
    } catch (err) {
      // const temp = generateMessageFromError(err);
      // NotificationLib.createErrorNotification(temp);
      return Promise.reject(err);
    } finally {
      //this.loading.makes = false;
    }
  } */

  /* async getVehicleModels(): Promise<void> {
    try {
      // this.loading.models = true;
      if (this.selectedMake) {
        this.models = await vehicleTypeLib.GetVehicleModel(this.selectedMake);
        console.log(this.models);
      } else {
        this.selectedModel = null;
        this.selectedYear = null;
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      //this.loading.models = false;
    }
  } */

  /*  async getVehicleYears(): Promise<void> {
    try {
      //this.loading.years = true;
      if (this.selectedMake && this.selectedModel) {
        this.years = await vehicleTypeLib.GetVehicleYear(
          this.selectedMake,
          this.selectedModel
        );
      } else {
        this.selectedYear = null;
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      //this.loading.years = false;
    }
  } */

  async submit(): Promise<void> {
    try {
      this.loading = true;
      if (this.form) {
        await this.form.submit();
      }
      this.close();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
      this.$emit("refresh");
    }
  }
}
