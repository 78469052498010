
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/store/modules/Loader";
import videosModal from "@/views/modals/Videos.vue";
import { videosHook } from "@/helpers/videos";
import { Video, VideoViewModel } from "@/models/videos";
import dayjs from "dayjs";
import VideoCategories from "@/views/modals/VideoCategories.vue";
import { NotificationLib } from "@/helpers";
import { Query } from "@/models/search";

interface VideoTableViewModel extends Video {
  createDateFormatted: string;
  statusFormatted: string;
}

@Component({
  components: {
    videosModal,
    VideoCategories,
  },
})
export default class Videos extends Vue {
  items: VideoTableViewModel[] = [];
  search = "";
  sortDesc = false;
  sortBy = "";
  modal = false;
  modalItem: Video | undefined | null = null;
  deleteModal = false;
  deleteItem = {} as Video;
  categoriesModal = false;
  query: Query = {
    searchString: "",
    filterOnList: 3,
    page: 0,
    itemsPerPage: -1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
  headers = [
    { text: "Title", value: "title" },
    { text: "Category", value: "category" },
    { text: "Uploaded By", value: "createByUser" },
    { text: "Uploaded Date", value: "createDateFormatted" },
    { text: "Status", value: "statusFormatted" },
    { text: "", value: "actions", sortable: false },
  ];
  keys = [
    { text: "Title", value: "title" },
    { text: "Category", value: "category" },
    { text: "Uploaded By", value: "createByUser" },
    { text: "Uploaded Date", value: "createDateFormatted" },
    { text: "Status", value: "statusFormatted" },
  ];
  status = [
    { description: "Pending", color: "grey darken-2" },
    { description: "Approved", color: "success" },
    { description: "Declined", color: "error" },
  ];

  openEdit(i: VideoTableViewModel): void {
    this.modalItem = i as Video;
    this.modal = true;
  }

  openAdd(): void {
    this.modal = true;
  }

  openDelete(i: VideoTableViewModel): void {
    this.deleteItem = i as Video;
    this.deleteModal = true;
  }

  openCategories(): void {
    this.categoriesModal = true;
  }

  async deleteVideo(): Promise<void> {
    try {
      Loader.setLoadingState(true);

      const res = await videosHook.DeleteVideo(this.deleteItem);
      NotificationLib.createSuccessNotification("Video Deleted");
      await this.init();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      Loader.setLoadingState(false);
    }
  }

  async setStatus(
    item: VideoTableViewModel,
    status: { description: string; color: string }
  ): Promise<void> {
    // console.log("Video:", item, "Status: ", status);
    if (status.description == "Pending") {
      item.status = 0;
    } else if (status.description == "Approved") {
      item.status = 1;
    } else if (status.description == "Declined") {
      item.status = 2;
    }
    try {
      Loader.setLoadingState(true);
      const res = await videosHook.UpdateVideoStatus(item);
      await this.init();
      NotificationLib.createSuccessNotification("Status Updated");
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  getStatusColor(status: string): string {
    var findRes = this.status.find((elem) => elem.description == status);
    if (findRes) {
      return findRes.color;
    } else {
      return "grey";
    }
  }

  formatDate(date: string): string {
    return dayjs(date).format("YYYY-MM-DD HH:mm");
  }

  formatStatus(statusId: number): string {
    if (statusId == 0) {
      return "Pending";
    } else if (statusId == 1) {
      return "Approved";
    } else if (statusId == 2) {
      return "Declined";
    } else {
      return "";
    }
  }

  async init(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      const res = await videosHook.GetAllVideos(this.query);
      this.items = [];
      res.videos.map((elem: Video) => {
        this.items.push({
          createByUser: elem.createByUser,
          createDate: elem.createDate,
          deleted: elem.deleted,
          description: elem.description,
          id: elem.id,
          status: elem.status,
          title: elem.title,
          updateByUser: elem.updateByUser,
          updateDate: elem.updateDate,
          videoUrl: elem.videoUrl,
          createDateFormatted: this.formatDate(elem.createDate),
          statusFormatted: this.formatStatus(elem.status),
          category: elem.category,
        });
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }
}
