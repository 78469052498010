
import { Component, PropSync, Prop, Vue, Watch } from "vue-property-decorator";
import { ImageFile } from "@/models/files";
import { Image } from "@/helpers/classes/Files";
@Component
export default class ImageAttachmentField extends Vue {
  @PropSync("picture") item!: ImageFile;
  // @Prop([Boolean]) readonly disabled: boolean | undefined;
  @Prop(Boolean) readonly disabled: boolean | undefined;
  file: File | null = null;
  @Prop() label!: string;
  @Prop() rules!: any;
  // -------------------------------------------------------------------
  get getPreview() {
    if (this.item == null || this.item.upload == "" || this.item.upload == null)
      return null;
    return `data:${this.item.mimeType};base64,${this.item.upload}`;
  }
  @Watch("picture")
  onPictureChanged(val: ImageFile) {
    if (val.upload == "") {
      this.file = null;
    }
  }
  async previewProfilePic() {
    // console.log("Gen image");
    this.item = await Image.init(this.file ? this.file : undefined);

    return Promise.resolve();
  }
}
