
import { Component, Vue, Watch } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import NavModule from "@/store/modules/Nav";
import { StorageLib } from "@/helpers";
import Nav from "@/store/modules/Nav";
import { Device } from "@capacitor/device";

@Component
export default class AppBar extends Vue {
  logoutModal = false;
  value: string | null | undefined = "home";
  mobileAppBarHeight = 65;
  flipDrawer(): void {
    // console.log("Flip drawer  to: ", !this.appDrawer);
    NavModule.setAppDrawer(!this.appDrawer);
  }
  get appDrawer(): boolean {
    return NavModule.getAppDrawer;
  }
  get canCalendar() {
    if (
      AuthModule.getProfile &&
      AuthModule.getProfile.companyDetails &&
      AuthModule.getProfile.companyDetails.companyType
    ) {
      const temp = Nav.getSystemCompanyTypes.find(
        (e: any) =>
          AuthModule.getProfile &&
          AuthModule.getProfile.companyDetails &&
          AuthModule.getProfile.companyDetails.companyType &&
          e.id == AuthModule.getProfile.companyDetails.companyType
      );
      //console.log("can calendar ", temp);
      if (temp && temp.description.includes("Mechanic")) return true;
    }

    return false;
  }
  async mounted(): Promise<void> {
    const devInfo = await Device.getInfo();
    if (devInfo.platform == "ios") {
      this.mobileAppBarHeight = 100;
    }
  }

  get tab() {
    return NavModule.getTab;
  }
  set tab(val: "login" | "register") {
    NavModule.setTab(val);
  }

  dash(): void {
    if (this.$route.name != "dashboard") {
      this.$router.push({ name: "dashboard" });
    }
  }

  logout(): void {
    AuthModule.logout();
    StorageLib.removeLogin();
    this.logoutModal = false;
  }

  get LoggedIn(): boolean {
    return AuthModule.isLoggedIn;
  }
}
