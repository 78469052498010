import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import dayjs from "dayjs";

interface dashboardStats {
  totalPendingVideos: number;
  totalRegisteredCompanies: number;
  totalRegisteredUsers: number;
  totalVehicleMakes: number;
  totalVehicles: number;
  totalVideos: number;
}

const dashboard = () => {
  const baseUrl = "/api/Dashboard";
  const GetDashboardStats = async (): Promise<dashboardStats> => {
    const res = await axiosInstance.get(baseUrl + "/GetDashboardStats");
    return Promise.resolve(res.data);
  };

  return { GetDashboardStats };
};

const dashboardHook = dashboard();
export { dashboardHook };
