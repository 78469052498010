
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import ImageAttachment from "@/components/fields/ImageAttachment.vue";
import Auth from "@/store/modules/Auth";
import Nav from "@/store/modules/Nav";
import Loader from "@/store/modules/Loader";
import { UserFormModel } from "@/models/user";
import { UserForm } from "@/helpers/classes/Form";

@Component({
  components: {
    ImageAttachment,
  },
})
export default class UsersModal extends Vue {
  @PropSync("open") modal!: boolean;
  @Prop() readonly user!: UserFormModel | undefined;
  @Prop() readonly userId!: string | null;
  form: UserForm | null = null;
  valid = false;
  type: "personal" | "business" = "personal";
  confirmPassword: string | null = null;
  reveal = false;
  loading = false;
  rules = {
    mileage: (v: string) => v == null || Number(v) >= 0 || "Cannot be empty",
    required: (v: string) => !!v || "Cannot be empty",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
    minFileSize: (value: { size: number }): boolean | string =>
      !value || value.size > 146484.375 || "Min File Dimensions are 500x300",
    vin: (value: { length: number }): boolean | string =>
      !value ||
      value.length == 17 ||
      "Standard VIN numbers are 17 characters long",
    capital: (v: string) => {
      const pattern = /[ A-Z]/;
      return pattern.test(v) || "Please use atleast 1 uppercase character";
    },
    number: (v: string) => {
      const pattern = /[0-9]/;
      return pattern.test(v) || "Please use atleast 1 numeric character";
    },
    normalCase: (v: string) => {
      const pattern = /[a-z]/;
      return pattern.test(v) || "Please use atleast 1 lowercase character";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return pattern.test(value) || "Please use atleast 1 special character";
    },
    password: (v: string) => {
      return (!!v && v.length >= 8) || "Please use atleast 8 characters";
    },
    email: (v: string) => {
      const pattern = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}/;
      return pattern.test(v) || "Please provide a valid e-mail address";
    },
    cell: (value: string | null = null): boolean | string => {
      if (value != null) {
        if (value.length == 10) return true;
      }
      return "Please provide a contact number";
    },
  };

  @Watch("open")
  onOpenChanged(val: boolean, old: boolean) {
    if (val == true) {
      this.form = new UserForm(this.userId, this.user);
      if (this.form && this.form.form.companyId) {
        this.type = "business";
      }
      this.onTypeChange();
    } else {
      if (this.form) this.form.reset();
    }
  }
  @Watch("form.form", {
    deep: true,
  })
  onFormChanges() {
    //console.log("Has changes");
    if (this.form && this.form.user) {
      this.form.compare();
    }
  }
  @Watch("type", { immediate: true })
  onTypeChange() {
    if (this.form && this.type == "personal") {
      this.form.form.userType = "Individual";
    }
  }

  get getUnchanged() {
    return this.form ? this.form.unchanged : true;
  }

  get roles() {
    return Nav.getSystemRoles.filter((role) => {
      return (
        role.normalizedName.includes("COMPANY") &&
        /*  (this.profile == null
          ? */ role.normalizedName.includes("DRIVER") == false
        /* : true) */
      );
    });
  }

  get companyType() {
    return Nav.getSystemCompanyTypes;
  }

  close(): void {
    this.$emit("close");
    this.type = "personal";
    this.modal = false;
  }

  async submit(): Promise<void> {
    try {
      this.loading = true;
      if (this.form) {
        await this.form.submit();
      }
      this.close();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
      this.$emit("refresh");
    }
  }
}
