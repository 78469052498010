import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import dayjs from "dayjs";
import { Company } from "@/models/company";

const register = () => {
  const baseUrl = "/api/Register";

  const GetAllCompanyAsync = async (): Promise<Company[]> => {
    const res = await axiosInstance.get(baseUrl + "/GetAllCompanyAsync");
    return Promise.resolve(res.data);
  };

  return { GetAllCompanyAsync };
};

const registerHook = register();
export { registerHook };
