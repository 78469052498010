import {
  Credentials,
  AuthToken,
  Registration,
  Profile,
  UserProfileSettings,
  GeneralUserModel,
  UserFormModel,
  User,
} from "@/models/user";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import dayjs from "dayjs";
import AuthModule from "@/store/modules/Auth";
import { StorageLib } from "@/helpers";
import Auth from "@/store/modules/Auth";
import { Company } from "@/models/company";
import { extend } from "vue/types/umd";

const UserLib = () => {
  const logout = async () => {
    AuthModule.logout();
    // const id = await Device.getId();
    // const res = await axiosInstance.post("/api/auth/logout", {
    //   deviceUuid: id.uuid,
    // });
    // return Promise.resolve();
  };

  /**
   * Returns a JS object representation of a Javascript Web Token from its common encoded
   * string form.
   *
   * @template T the expected shape of the parsed token
   * @param {string} token a Javascript Web Token in base64 encoded, `.` separated form
   * @returns {(T | undefined)} an object-representation of the token
   * or undefined if parsing failed
   */
  const getParsedJwt = <T extends object = { [k: string]: string | number }>(
    token: string
  ): T | undefined => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch {
      return undefined;
    }
  };
  const validateJWTExp = (val: number | string) => {
    const tokenTime = dayjs.unix(typeof val == "string" ? Number(val) : val);
    const currentTime = dayjs();
    //console.log("Token : ", tokenTime > currentTime, tokenTime, currentTime);
    if (tokenTime > currentTime) {
      return true;
    }
    return false;
  };
  const parseAndValidate = () => {
    // console
  };
  const processNewJWT = async (val: AuthToken) => {
    const token = getParsedJwt(val.accessToken);
    //console.warn("Token ", token);
    if (token && token.exp) {
      // const temp = dayjs.unix(Number(token.iat)).add(2, "minute");
      // console.log("Testing Process ", token.iat, dayjs(temp.unix()).toString());
      // const valid = validateJWTExp(temp.unix());
      const valid = validateJWTExp(token.exp);

      if (valid) {
        AuthModule.setAuthToken(val);
        if (val.rememberMe) {
          await StorageLib.setLogin({
            rememberMe: val.rememberMe,
            accessToken: val.accessToken,
            tokenType: val.tokenType,
            expiresIn: val.expiresIn,
          });
        }

        return Promise.resolve(true);
      }
    }
    AuthModule.setAuthToken(null);
    await StorageLib.removeLogin();
    return Promise.resolve(false);
  };

  const login = async (
    val: Credentials,
    rememberMe: boolean
  ): Promise<AuthToken> => {
    const res = await axiosNoAuth
      .post(`/api/Login/Login`, {
        Username: val.email,
        Password: val.password,
      })
      .catch(async (e: any) => {
        await logout(); // Log the user out whenever the user is in the wrong spot
        // AuthModule.logout();
        return Promise.reject(e);
      });

    //console.log("Header ", res);
    // a lot of field inside the res.
    const temp: AuthToken = {
      rememberMe: rememberMe,
      accessToken: res.data.accessToken,
      expiresIn: res.data.expiresIn,
      tokenType: res.data.tokenType,
    };
    return Promise.resolve(temp);
  };
  const getOtherUserProfile = async (uuid: string): Promise<Profile> => {
    const res = await axiosInstance.get(`/api/manageUsers/GetUserById`, {
      params: { id: uuid },
    });
    return Promise.resolve(res.data);
  };
  // todo: this should take in nothing, use jwt to detect user and pass get userprofile back. Enquire this
  const getProfile = async (uuid?: string): Promise<Profile> => {
    if (uuid !== undefined) {
      const res = await axiosNoAuth.get(`/api/Register/GetUserById`, {
        params: { id: uuid },
      });
      //log("Public Profile", res.data);
      AuthModule.setProfile(res.data);
      return Promise.resolve(res.data);
    } else {
      const res = await axiosInstance
        .get(`/api/manageUsers/GetUserProfile`)
        .catch(async (e: any) => {
          await logout(); // Log the user out whenever the user is in the wrong spot
          // AuthModule.logout();
          return Promise.reject(e);
        });
      // console.log("Private Profile: ", res.data);
      AuthModule.setProfile(res.data);
      return Promise.resolve(res.data);
    }
  };

  // const getDriverProfile = async (uuid: string): Promise<Profile> => {
  //   // const res = await axiosInstance.get(`/api/manageUsers/GetDriverProfile`, {
  //   const res = await axiosNoAuth.get(`/api/Register/GetUserById`, {
  //     params: {
  //       id: uuid,
  //     },
  //   });
  //   return Promise.resolve(res.data);
  // };

  const register = async (registration: Registration) => {
    await axiosNoAuth.post("/api/Register/addUser", registration);
    return Promise.resolve();
  };
  const updateProfile = async (registration: Registration) => {
    await axiosInstance.post(
      "/api/ManageUsers/UpdateUserProfile",
      registration
    );
    return Promise.resolve();
  };
  const updateUserSettings = async (settings: UserProfileSettings) => {
    await axiosInstance.post(
      "/api/ManageUsers/UpdateUserProfileSettings",
      settings
    );
    return Promise.resolve();
  };

  const deregister = async (): Promise<any> => {
    if (Auth.getProfile) {
      const email = Auth.getProfile.userDetails.email;
      if (email) {
        const res = await axiosInstance.get(`/api/Login/RemoveAccount`, {
          params: email,
        });
      }
    }

    return Promise.resolve();
  };

  interface UpdateUserWithIdModel extends UserFormModel {
    id: string | null;
  }

  const UpdateUserWithId = async (data: UpdateUserWithIdModel) => {
    await axiosInstance.post("/api/ManageUsers/UpdateUserWithId", data);
    return Promise.resolve();
  };

  const getAllUsers = async (): Promise<
    { company: Company; user: GeneralUserModel }[]
  > => {
    const res = await axiosInstance.get(`/api/ManageUsers/GetAllUsers`);
    return Promise.resolve(res.data);
  };

  return {
    getParsedJwt,
    login,
    getProfile,
    register,
    deregister,
    logout,
    processNewJWT,
    validateJWTExp,
    updateProfile,
    updateUserSettings,
    getOtherUserProfile,
    getAllUsers,
    UpdateUserWithId,
    // getDriverProfile, // See GetProfile
  };
};

const userLib = UserLib();
export { userLib };
