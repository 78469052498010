import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import dayjs from "dayjs";
import { EntityQuery, SearchMechanicResponse } from "@/models/business";
import { Company } from "@/models/company";

const directory = () => {
  const baseUrl = "/api/Directory";

  const SearchMechanic = async (
    data: EntityQuery
  ): Promise<SearchMechanicResponse> => {
    const res = await axiosInstance.post(baseUrl + "/SearchMechanic", data);
    return Promise.resolve(res.data);
  };

  const ApproveMechanic = async (data: Company): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/ApproveMechanic", data);
    return Promise.resolve(res.data);
  };

  return { SearchMechanic, ApproveMechanic };
};

const directoryHook = directory();
export { directoryHook };
