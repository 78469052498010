import { axiosInstance } from "@/plugins/axios";
import {
  AdminNotificationModel,
  AdminNotificationModel_CREATE,
} from "@/models/adminNotifications";

const adminNotifications = () => {
  const baseUrl = "/api/AdminNotification";
  const GetAdminNotifications = async (): Promise<any> => {
    const res = await axiosInstance.get(baseUrl + "/GetAdminNotifications");
    return Promise.resolve(res.data);
  };

  const AddAdminNotification = async (
    data: AdminNotificationModel_CREATE
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/AddAdminNotification",
      data
    );
    return Promise.resolve(res.data);
  };

  const UpdateAdminNotification = async (
    data: AdminNotificationModel
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/UpdateAdminNotification",
      data
    );
    return Promise.resolve(res.data);
  };

  const DeleteAdminNotification = async (
    data: AdminNotificationModel
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl + "/DeleteAdminNotification",
      data
    );
    return Promise.resolve(res.data);
  };

  return {
    GetAdminNotifications,
    AddAdminNotification,
    UpdateAdminNotification,
    DeleteAdminNotification,
  };
};

const adminNotificationsHook = adminNotifications();
export { adminNotificationsHook };
