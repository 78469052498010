import { AuthToken } from "@/models/user";
import { Preferences } from "@capacitor/preferences"; // Session
const TOKEN_CONST = "iscarti_admin_fcm_token";

const StorageLib = () => {
  const darkMode = "iscarti_admin_darkmode";
  const key = "za.co.admin_iscarti.app";
  const setDarkMode = async (val: boolean) => {
    await Preferences.set({
      key: darkMode,
      value: val === true ? "enabled" : "disabled",
    });
  };
  const getDarkMode = async () => {
    const res = await Preferences.get({
      key: darkMode,
    });
    return res.value;
  };

  const setLogin = async (e: AuthToken) => {
    await Preferences.set({
      key: key,
      value: JSON.stringify(e),
    }); // Session ?
  };

  const getLogin = async (): Promise<AuthToken | null> => {
    const { value } = await Preferences.get({
      key: key,
    });
    if (value != null) {
      return JSON.parse(value);
    } else return null;
  };

  const removeLogin = async () => {
    await Preferences.remove({ key: key });
  };
  const setFcmToken = async (token: string) => {
    await Preferences.set({
      key: TOKEN_CONST,
      value: token,
    });
  };
  const getFcmToken = async () => {
    const res = await Preferences.get({
      key: TOKEN_CONST,
    });
    return res.value;
  };
  const removeFcmToken = async () => {
    await Preferences.remove({
      key: TOKEN_CONST,
    });
  };

  return {
    removeFcmToken,
    getFcmToken,
    setFcmToken,
    setLogin,
    getLogin,
    removeLogin,
    setDarkMode,
    getDarkMode,
  };
};
const storageLib = StorageLib();
export { storageLib };
