
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/store/modules/Loader";
import { eventsHook } from "@/helpers/events";
import EventModal from "@/views/modals/Events.vue";
import { Event } from "@/models/event";
import dayjs from "dayjs";
import { NotificationLib } from "@/helpers";
import { Query } from "@/models/search";
import EventCategories from "@/views/modals/EventCategories.vue";

@Component({ components: { EventModal, EventCategories } })
export default class Events extends Vue {
  items: Event[] = [];
  search = "";
  sortDesc = false;
  sortBy = "";
  modal = false;
  modalItem: Event | undefined | null = null;
  deleteModal = false;
  categoriesModal = false;
  deleteItem = {} as Event;
  query: Query = {
    searchString: "",
    filterOnList: 3,
    page: 0,
    itemsPerPage: -1,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
  headers = [
    { text: "Title", value: "eventTitle" },
    { text: "Start Date & Time", value: "start" },
    { text: "End Date & Time", value: "ending" },
    { text: "Category", value: "category" },
    { text: "Location", value: "location" },
    { text: "", value: "actions", sortable: false },
  ];
  keys = [
    { text: "Title", value: "eventTitle" },
    { text: "Start Date & Time", value: "start" },
    { text: "End Date & Time", value: "ending" },
    { text: "Category", value: "category" },
    { text: "Location", value: "location" },
  ];

  openEdit(i: Event): void {
    this.modalItem = i;
    this.modal = true;
  }

  openAdd(): void {
    this.modal = true;
  }

  openCategories(): void {
    this.categoriesModal = true;
  }

  openDelete(i: Event): void {
    this.deleteItem = i;
    this.deleteModal = true;
  }

  async deleteEvent(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      this.deleteItem.ending =
        dayjs(this.deleteItem.ending).format("YYYY-MM-DD") +
        "T" +
        dayjs(this.deleteItem.ending).format("HH:mm");
      this.deleteItem.start =
        dayjs(this.deleteItem.start).format("YYYY-MM-DD") +
        "T" +
        dayjs(this.deleteItem.start).format("HH:mm");
      const res = await eventsHook.RemoveEventCalendar(this.deleteItem);
      NotificationLib.createSuccessNotification("Event Deleted");
      this.init();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      Loader.setLoadingState(false);
    }
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      const res = await eventsHook.GetAllEventCalendar(this.query);
      this.items = [];
      res.eventCalendars.map((elem: Event) => {
        this.items.push({
          _id: elem._id,
          createdBy: elem.createdBy,
          createdDate: elem.createdDate,
          deletedDate: elem.deletedDate,
          description: elem.description,
          ending: dayjs(elem.ending).format("YYYY-MM-DD HH:mm"),
          eventTitle: elem.eventTitle,
          start: dayjs(elem.start).format("YYYY-MM-DD HH:mm"),
          updatedBy: elem.updatedBy,
          updatedDate: elem.updatedDate,
          category: elem.category,
          location: elem.location,
          url: elem.url,
        });
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }
}
