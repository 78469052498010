
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/store/modules/Loader";
import { adminNotificationsHook } from "@/helpers/adminNotifications";
import PushNotificationsModal from "@/views/modals/PushNotifications.vue";
import { AdminNotificationModel } from "@/models/adminNotifications";
import dayjs from "dayjs";
import { NotificationLib } from "@/helpers";
import { Query } from "@/models/search";
import EventCategories from "@/views/modals/EventCategories.vue";
import PushNotificationModal from "@/views/modals/PushNotifications.vue";

@Component({ components: { PushNotificationsModal } })
export default class PushNotifications extends Vue {
  items: AdminNotificationModel[] = [];
  search = "";
  sortDesc = false;
  sortBy = "";
  modal = false;
  modalItem: AdminNotificationModel | undefined | null = null;
  deleteModal = false;
  categoriesModal = false;
  deleteItem = {} as AdminNotificationModel;
  headers = [
    { text: "Title", value: "title" },
    { text: "Message", value: "message" },
    { text: "Date", value: "notificationStartDate" },
    { text: "Sent", value: "notificationSent" },
    { text: "", value: "actions", sortable: false },
  ];
  keys = [
    { text: "Title", value: "title" },
    { text: "Message", value: "message" },
    { text: "Date", value: "notificationStartDate" },
    { text: "Sent", value: "notificationSent" },
  ];

  openEdit(i: AdminNotificationModel): void {
    this.modalItem = i;
    this.modal = true;
  }

  openAdd(): void {
    this.modal = true;
  }

  openCategories(): void {
    this.categoriesModal = true;
  }

  openDelete(i: AdminNotificationModel): void {
    this.deleteItem = i;
    this.deleteModal = true;
  }

  async deleteEvent(): Promise<void> {
    /* try {
      Loader.setLoadingState(true);
      this.deleteItem.ending =
        dayjs(this.deleteItem.ending).format("YYYY-MM-DD") +
        "T" +
        dayjs(this.deleteItem.ending).format("HH:mm");
      this.deleteItem.start =
        dayjs(this.deleteItem.start).format("YYYY-MM-DD") +
        "T" +
        dayjs(this.deleteItem.start).format("HH:mm");
      const res = await eventsHook.RemoveEventCalendar(this.deleteItem);
      NotificationLib.createSuccessNotification("Event Deleted");
      this.init();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      Loader.setLoadingState(false);
    } */
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      const res = await adminNotificationsHook.GetAdminNotifications();
      this.items = [];
      res.map((elem: AdminNotificationModel) => {
        this.items.push({
          createDate: elem.createDate,
          message: elem.message,
          notificationStartDate: dayjs(elem.notificationStartDate).format(
            "YYYY-MM-DD"
          ),
          title: elem.title,
          createBy: elem.createBy,
          deletedBy: elem.deletedBy,
          deletedDate: elem.deletedDate,
          id: elem.id,
          isRecurring: elem.isRecurring,
          notificationSent: elem.notificationSent,
          numberOfrecurringDays: elem.numberOfrecurringDays,
          updatedBy: elem.updatedBy,
          updatedDate: elem.updatedDate,
        });
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }
}
