
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/store/modules/Loader";
import { vehicleTypeLib } from "@/helpers/vehicleTypes";
import VehicleTypes from "@/views/modals/VehicleTypes.vue";
import VehicleCategories from "@/views/modals/VehicleCategories.vue";
import { VehicleTypes as VehicleTypesModel } from "@/models/vehicleTypes";

@Component({ components: { VehicleTypes, VehicleCategories } })
export default class Vehicles extends Vue {
  items: VehicleTypesModel[] = [];
  search = "";
  sortDesc = false;
  sortBy = "make";
  modal = false;
  modalItem: VehicleTypesModel | undefined | null = null;
  deleteModal = false;
  deleteItem = {} as any;
  categoriesModal = false;
  headers = [
    { text: "Make", value: "make" },
    { text: "Model", value: "model" },
    { text: "Year", value: "year" },
    { text: "Category", value: "category" },
    { text: "", value: "actions", sortable: false },
  ];
  keys = [
    { text: "Make", value: "make" },
    { text: "Model", value: "model" },
    { text: "Year", value: "year" },
    { text: "Category", value: "category" },
  ];

  openEdit(i: VehicleTypesModel): void {
    this.modalItem = i;
    this.modal = true;
  }

  openAdd(): void {
    /*  this.modalData.type = "add";
    this.modalData.fields = {}; */
    this.modal = true;
  }

  openDelete(i: any): void {
    this.deleteItem = i;
    this.deleteModal = true;
  }

  openCategories(): void {
    this.categoriesModal = true;
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    try {
      Loader.setLoadingState(true);
      const res = await vehicleTypeLib.GetAllVehicleTypes();
      //console.log("Vehicles Types:", res);
      this.items = res;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      Loader.setLoadingState(false);
    }
  }
}
